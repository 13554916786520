/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDAlert from 'components/MDAlert'

// Material Dashboard 2 PRO React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import DataTable from 'examples/Tables/DataTable'
import MDButton from 'components/MDButton'
import { Tooltip, IconButton, Modal, CardContent } from '@mui/material'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import CrudService from 'services/cruds-service'
import { useState, useEffect } from 'react'
import { AbilityContext, Can } from 'Can'
import { useAbility } from '@casl/react'
import getId from 'services/helper-service'
import moment from 'moment'

function RivioWebHookManagement() {

  const [data, setData] = useState([])
  const [tableData, setTableData] = useState([])
  const [paramTableData, setParamTableData] = useState()
  const [openViewModal, setOpenViewModal] = useState(false)

  useEffect(() => {
    ; (async () => {
      const response = await CrudService.getRivioWebHookLogs()
      setData(response.data)
    })()
    document.title = `Rivio | Webhook Logs`
  }, [])

  useEffect(() => {
    setTableData(getRows(data))
  }, [data])

  const getRows = (info) => {
    let updatedInfo = info.map((row) => {
      return {
        id: row.id,
        service: row.WebhookType?.value,
        status: row.StatusType.status_name,
        eventdate: row.created_at,
        ...row
      }
    })
    return updatedInfo
  }

  const format_custom_json = (jsonString) => {
    jsonString = jsonString.replace(/\\/g, '')

    return jsonString
  }

  const clickViewHandler = (id, value) => {
    console.log('value', value)
    setOpenViewModal(true)
    // const filteredValue = Object.keys(value).reduce((acc, key) => {
    //   if (key !== 'map_status') {
    //     acc[key] = value[key]
    //   }
    //   return acc
    // }, {})

    // Set the filtered data in the state
    setParamTableData(value.value)
  }

  const handleViewClose = () => {
    setOpenViewModal(false)
  }

  const dataTableData = {
    columns: [
      { Header: 'ID', accessor: 'id', width: '10%' },
      { Header: 'Service', accessor: 'service', width: '10%' },
      { Header: 'Status', accessor: 'status' },
      {
        Header: 'Event Date',
        accessor: 'eventdate',
        isSortedDesc: true,
        isSorted: true,
        Cell: (info) => {
          return (
            <div
              style={{
                whiteSpace: 'normal',
                maxWidth: '300px',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
              }}
            >
              {moment(info.cell.row.original.eventdate).format(
                'MMMM DD, YYYY h:mm:ss A',
              )}
            </div>
          )
        },
      },
      {
        Header: 'Target',
        accessor: '',
        Cell: (info) => {
          return (
            <div>
              
              <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                  onClick={() =>
                    clickViewHandler(
                      info.cell.row.original.id,
                      info.cell.row.original,
                    )
                  }
                >
                  View
                </MDButton>
            </div>
          )
        },
      },
    ],

    rows: tableData,
  }

  const renderTableCell = (data) => {
    // If the data is an object (JSON), render each key-value pair
    if (typeof data === 'object' && data !== null) {
      return (
        <Table>
          <TableBody>
            {Object.entries(data).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{renderTableCell(value)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )
    }
    return data
  }

  const DynamicDataTable = (EventData) => {
    // Parse JSON data and extract keys for table columns
    const columns = Object.keys(EventData)

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column}>
                  {renderTableCell(EventData[column])}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <DataTable
              table={dataTableData}
              canSearch={true}
              isSorted={true}
              entriesPerPage={true}
            />
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
      <Modal
        open={openViewModal}
        onClose={handleViewClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%', // Set the desired width here
            backgroundColor: 'white',
            boxShadow: 24,
            p: 4,
            maxHeight: '70vh', // Set max height to enable scrolling
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <CardContent>
            {/* <DynamicDataTable EventData={paramTableData} /> */}
            <pre>{JSON.stringify(paramTableData, null, 2) }</pre>
          </CardContent>
        </Card>
      </Modal>
    </DashboardLayout>
  )
}

export default RivioWebHookManagement
