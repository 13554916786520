import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { jwtDecode } from 'jwt-decode'
import { styled } from '@mui/system'
import {
  Container,
  Typography,
  TextField,
  Modal,
  Card,
  CardContent,
  CardHeader,
  Box,
  Tooltip,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
  Paper,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import Badge from '@mui/material/Badge'
import Autocomplete from '@mui/material/Autocomplete'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import CrudService from 'services/cruds-service'
import MDEditor from 'components/MDEditor'
import { red } from '@mui/material/colors'
import ServiceRequestConfirmForm from './confirm'
import { RULES } from 'utils/constant'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    whiteSpace: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 'auto',
    padding: '6px',
  },
}))

// Placeholder for FormField component
const FormField = ({ type, label, name, value, onChange, error, ...props }) => (
  <TextField
    type={type}
    label={label}
    name={name}
    value={value}
    onChange={onChange}
    error={!!error}
    helperText={error}
    fullWidth
    margin="normal"
    {...props}
  />
)

const ServiceRequestForm = React.memo(
  ({
    deviceId,
    device,
    serviceRequestEditFlag,
    serviceRequest,
    setServiceModal,
    setServiceRequests,
    setServiceStatusUpdate,
    showToast,
  }) => {
    console.log(serviceRequest, '=========service request info=============')

    const isMobile = useMediaQuery('(max-width:600px)')

    let storedDeviceData = localStorage.getItem('device_params')
    let device_data = storedDeviceData ? JSON.parse(storedDeviceData) : null

    const [timePeriodOptions, setTimePeriod] = useState([])
    const [selectedTimePeriod, setSelectedTimePeriod] = useState('')
    const [smartChecked, setSmartChecked] = useState(false)
    const [selectedValue, setSelectedValue] = useState(null)

    const [formData, setFormData] = useState({
      device_id: deviceId,
      entity_id: device?.EntityAddressItem?.Entity?.entity_id,
      address_id: device?.EntityAddressItem?.Address?.address_id,
      address_item_id: device?.EntityAddressItem?.address_item_id,
      provider_id: serviceRequest ? serviceRequest.provider_id : '',
      service_request_auto_completion_id: selectedValue,
      item_id: serviceRequest ? serviceRequest.item_id : '',
      priority_id: serviceRequest ? serviceRequest.priority_id : '',
      description: serviceRequest ? serviceRequest.description : '',
      request_date: serviceRequest ? serviceRequest.request_date : '',

      device_params: device_data,
    })

    const [confirmFormData, setConfirmFormData] = useState({
      entity_name: device?.EntityAddressItem?.Entity.name
        ? device?.EntityAddressItem?.Entity.name
        : device?.EntityAddressItem?.Entity.first_name +
          ' ' +
          device?.EntityAddressItem?.Entity.last_name,
      address_name:
        device?.EntityAddressItem?.Address?.street_address1 +
        device?.EntityAddressItem?.Address?.street_addresss +
        ' ' +
        device?.EntityAddressItem?.Address?.country +
        ' ' +
        device?.EntityAddressItem?.Address?.city +
        ' ' +
        device?.EntityAddressItem?.Address?.state,
      address_item_name: device?.EntityAddressItem?.name,
      priority_name: '',
      service_item_name: '',
      provider_name: '',
      description: '',
      request_date: '',
      scheduled_date: '',
    })

    const [serviceItems, setServiceItems] = useState([])
    const [serviceItem, setServiceItem] = useState({})
    const [providers, setProviders] = useState([])
    const [priorities, setPriorities] = useState([])
    const [nextStatusInfo, setNextStatus] = useState([])
    const [address, setAddress] = useState([])
    const [userId, setUserId] = useState('')
    const [
      entityAddressItemProviders,
      setEntityAddressItemProviders,
    ] = useState([])
    const [addressItemId, setAddressItemId] = useState(device?.address_item_id)
    const [
      providersRelatedToServiceItem,
      setProvidersRelatedToServiceItem,
    ] = useState([])
    const [mapX, setMapX] = useState(
      device?.EntityAddressItem?.Address?.latitude,
    )
    const [mapY, setMapY] = useState(
      device?.EntityAddressItem?.Address?.longitude,
    )
    const [errors, setErrors] = useState({})
    const [uneditableField, setUnEditableField] = useState(false)
    const [roleId, setRoleId] = useState()
    const [
      serviceRequestAutoCompletions,
      setServiceRequestAutoCompletions,
    ] = useState([])
    const [
      openServiceRequestConfirmModal,
      setOpenServiceRequestConfirmModal,
    ] = useState(false)

    const today = new Date().toISOString().split('T')[0]

    useEffect(() => {
      ;(async () => {
        const timePeriodRes = await CrudService.getTimePeriod()
        const defaultTimePeriod = timePeriodRes.find(
          (item) => item.is_default === 1,
        )
        console.log(defaultTimePeriod, '============defaultTimePeriod')
        setSelectedTimePeriod(defaultTimePeriod)

        setTimePeriod(timePeriodRes)

        const serviceRequestAutoCompletionsRes = await CrudService.getServiceRequestAutoCompletions()
        setServiceRequestAutoCompletions(serviceRequestAutoCompletionsRes)
      })()
    }, [])

    useEffect(() => {
      const fetchData = async () => {
        try {
          const prioritiessResponse = await CrudService.getPriorities()
          const priorities = prioritiessResponse.data
          console.log(
            prioritiessResponse,
            '=======prioritiessResponse==========',
          )
          setPriorities(prioritiessResponse.data)

          const serviceItemsResponse = await CrudService.getServiceItems()

          const serviceItems = serviceItemsResponse.data

          if (serviceItems.length == 1) {
            console.log('hi')
            const serviceItems = serviceItemsResponse.data
            console.log(serviceItems[0].item_id, 'service item id')
            const providersRelatedToServiceItemRes = await CrudService.getProvidersRelatedToServiceItem(
              serviceItems[0].item_id,
            )
            //  const  providersRelatedToServiceItem = providersRelatedToServiceItemRes.data;
            const addressItemProvidersResponse = await CrudService.getAddressItemProviders(
              addressItemId,
            )
            setEntityAddressItemProviders(addressItemProvidersResponse.data)
            const providersRelatedToAddressItem = providersRelatedToServiceItemRes.data.filter(
              (item1) =>
                addressItemProvidersResponse.data.some(
                  (item2) => item1.provider_id === item2.provider_id,
                ),
            )

            console.log(
              providersRelatedToAddressItem,
              'providersRelatedToAddressItem',
            )
            if (providersRelatedToAddressItem.length == 1) {
              console.log('relatedtoaddressItem')
              setServiceItems(serviceItemsResponse.data)

              setProvidersRelatedToServiceItem(providersRelatedToAddressItem)

              console.log(priorities[0], '===========setform data priority')
              setFormData({
                ...formData,
                priority_id: priorities[0].priority_id,
                item_id: serviceItems[0].item_id,
                provider_id: providersRelatedToAddressItem[0].provider_id,
              })
              setConfirmFormData({
                ...confirmFormData,
                priority_name: priorities[0].priority_name,
                service_item_name: serviceItems[0].item_name,
                provider_name: providersRelatedToAddressItem[0].provider_name,
              })
            }
          } else {
            setServiceItems(serviceItems)
          }

          const providersResponse = await CrudService.getProviders()
          setProviders(providersResponse)

          const addressItemProvidersResponse = await CrudService.getAddressItemProviders(
            addressItemId,
          )
          setEntityAddressItemProviders(addressItemProvidersResponse.data)
        } catch (error) {
          console.error('Error fetching data:', error)
        }

        const street_address1 =
          device?.EntityAddressItem?.Address?.street_address1 || ''
        const street_address =
          device?.EntityAddressItem?.Address?.street_addresss || ''
        const country = device?.EntityAddressItem?.Address?.country || ''
        const city = device?.EntityAddressItem?.Address?.city || ''
        const state = device?.EntityAddressItem?.Address?.state || ''
        const address =
          street_address1 +
          street_address +
          ' ' +
          country +
          ' ' +
          city +
          ' ' +
          state
        setAddress(address)

        if (serviceRequestEditFlag == true) {
          const token = localStorage.getItem('token')

          if (token) {
            const decoded = jwtDecode(token)
            console.log(decoded, '=================user token==========')
            setUserId(decoded.id)
            const role_id = decoded.role_id
            setRoleId(role_id)
            const current_status_id = serviceRequest?.status_type_id

            const service_request_status_info = {
              role_id: role_id,
              current_status_id: current_status_id,
            }

            console.log(
              service_request_status_info,
              '=========service_request_status_info',
            )
            const rolePermissionResponse = await CrudService.getNextRole(
              service_request_status_info,
            )

            console.log(rolePermissionResponse.data, '=========next role ')

            setNextStatus(rolePermissionResponse.data)

            if (service_request_status_info.role_id == '6') {
              setUnEditableField(true)
            }
            if (service_request_status_info.role_id == '7') {
              setUnEditableField(false)
            }
          }
        }
      }

      fetchData()
    }, [device])

    useEffect(() => {
      const fetchData = async () => {
        try {
          const providersRelatedToServiceItemRes = await CrudService.getProvidersRelatedToServiceItem(
            formData.item_id,
          )
          const providersRelatedToAddressItem = providersRelatedToServiceItemRes.data.filter(
            (item1) =>
              entityAddressItemProviders.some(
                (item2) => item1.provider_id === item2.provider_id,
              ),
          )

          console.log(providersRelatedToAddressItem)
          setProvidersRelatedToServiceItem(providersRelatedToAddressItem)
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }
      fetchData()
    }, [formData.item_id])

    const handleChange = (event) => {
      setSmartChecked(event.target.checked)
    }
    const clickServiceItem = async (service_item_id, service_item_name) => {
      console.log('hi')

      console.log(service_item_id, 'service item id')
      const providersRelatedToServiceItemRes = await CrudService.getProvidersRelatedToServiceItem(
        service_item_id,
      )
      //  const  providersRelatedToServiceItem = providersRelatedToServiceItemRes.data;
      const addressItemProvidersResponse = await CrudService.getAddressItemProviders(
        addressItemId,
      )
      setEntityAddressItemProviders(addressItemProvidersResponse.data)
      const providersRelatedToAddressItem = providersRelatedToServiceItemRes.data.filter(
        (item1) =>
          addressItemProvidersResponse.data.some(
            (item2) => item1.provider_id === item2.provider_id,
          ),
      )

      console.log(
        providersRelatedToAddressItem,
        'providersRelatedToAddressItem',
      )

      setProvidersRelatedToServiceItem(providersRelatedToAddressItem)

      console.log(priorities[0], '===========setform data priority')

      if (providersRelatedToAddressItem.length == 1) {
        setFormData({
          ...formData,
          item_id: service_item_id,
          provider_id: providersRelatedToAddressItem[0].provider_id,
        })
        setConfirmFormData({
          ...confirmFormData,
          service_item_name: service_item_name,
          provider_name: providersRelatedToAddressItem[0].provider_name,
        })
      } else {
        setFormData({
          ...formData,
          item_id: service_item_id,
        })
        setConfirmFormData({
          ...confirmFormData,
          service_item_name: service_item_name,
        })
      }
    }

    const handleRadioChange = (event, rowId) => {
      setSelectedValue(rowId) // Set selected row ID when radio button is clicked
      setFormData({
        ...formData,
        service_request_auto_completion_id: rowId,
      })
    }

    const rows = [
      { id: 1, name: 'Row 1', description: 'This is row 1' },
      { id: 2, name: 'Row 2', description: 'This is row 2' },
      { id: 3, name: 'Row 3', description: 'This is row 3' },
    ]

    const validate = () => {
      console.log(formData, '========formData=====')
      let tempErrors = {}
      if (!formData.priority_id) {
        tempErrors.priority = 'Priority is required'
      }
      if (!formData.item_id) {
        tempErrors.serviceItem = 'Service Item is required'
      }
      if (!formData.provider_id) {
        tempErrors.provider = 'Provider is required'
      }

      if (!formData.request_date) {
        tempErrors.requestDate = 'Requested Date is required'
      }

      setErrors(tempErrors)
      return Object.keys(tempErrors).length === 0
    }
    const handleSubmit = async (e) => {
      e.preventDefault()
      console.log(formData, '========formData11')
      if (validate()) {
        if (formData) {
          if (serviceRequestEditFlag == true) {
            await CrudService.updateServiceRequest(
              formData,
              serviceRequest?.service_request_id,
            )
            const now = moment().format('YYYY-MM-DD HH:mm:ss')
            setServiceStatusUpdate(now)
            setServiceModal(false)
          } else {
            setOpenServiceRequestConfirmModal(true)
          }
        }
      }
    }
    const handleServiceModalClose = () => {
      setOpenServiceRequestConfirmModal(false)
    }
    const clickNextStatusBtn = async (e) => {
      console.log(e.target.id)
      const nextStatusId = e.target.id
      const serviceRequestId = serviceRequest?.service_request_id

      const newServiceRequestInfo = {
        status_type_id: nextStatusId,
        service_request_id: serviceRequestId,
        device_params: localStorage.getItem('device_params')
          ? localStorage.getItem('device_params')
          : '',
      }

      const changeServiceStatusRes = await CrudService.updateServiceStatus(
        newServiceRequestInfo,
      )
      if (changeServiceStatusRes) {
        const now = moment().format('YYYY-MM-DD HH:mm:ss')
        setServiceStatusUpdate(now)
        setServiceModal(false)
        showToast({
          status: '201',
          message: changeServiceStatusRes.data.msg,
        })
      }
    }
    const showNextStatusButton = () => {
      return nextStatusInfo?.map((status) => (
        <MDButton
          id={status.next_status_id}
          onClick={clickNextStatusBtn}
          sx={{ marginRight: '10px' }}
          className={status?.ButtonType?.css_class}
        >
          {status.ButtonType.label}
        </MDButton>
      ))
    }

    return (
      <Container sx={{ padding: '0px', position: 'relative', height: '100%' }}>
        <MDBox sx={{ height: '50px' }}>
          <Typography
            variant="h4"
            component="h2"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            Service Request
            {serviceRequestEditFlag && (
              <StyledBadge
                color={
                  serviceRequest?.StatusOption?.BadgeDetail?.color || 'primary'
                }
                variant={serviceRequest?.StatusOption?.BadgeDetail?.variant}
                badgeContent={
                  serviceRequest?.StatusOption?.StatusType?.status_name
                }
                style={{ marginLeft: '50px' }}
              ></StyledBadge>
            )}
          </Typography>
        </MDBox>
        <MDBox component="form" method="POST" onSubmit={handleSubmit}>
          <CardContent
            sx={{
              maxHeight: { xs: '70vh', sm: '80vh', md: '540px', lg: '540px' },
              overflowY: 'auto',
              overflowX: 'hidden',
              padding: '0px !important',
            }}
          >
            {/* Entity */}

            <MDInput
              fullWidth
              sx={{ marginTop: '20px' }}
              type="text"
              label={
                <Tooltip
                  title="The organization or entity making the request."
                  placement="right"
                  arrow
                  disableHoverListener={isMobile} // Disable hover on mobile devices
                  disableTouchListener={!isMobile} // Enable touch on mobile devices
                  disableFocusListener={!isMobile} // Enable focus-based tooltips on mobile
                >
                  <Box>
                    <i
                      className="fas fa-building"
                      style={{ marginRight: '5px' }}
                    ></i>
                    Entity
                  </Box>
                </Tooltip>
              }
              name="entity"
              value={
                device?.EntityAddressItem?.Entity.name
                  ? device?.EntityAddressItem?.Entity.name
                  : device?.EntityAddressItem?.Entity.first_name +
                    ' ' +
                    device?.EntityAddressItem?.Entity.last_name
              }
              onChange={(e) => {
                setFormData({
                  ...formData,
                  entity_id: device?.EntityAddressItem?.Entity?.entity_id,
                })
              }}
              disabled={true}
            />

            {/* Address  */}

            <MDInput
              fullWidth
              sx={{ marginTop: '20px' }}
              type="text"
              label={
                <Tooltip
                  title="The address where the service will take place."
                  placement="right"
                  arrow
                  disableHoverListener={isMobile} // Disable hover on mobile devices
                  disableTouchListener={!isMobile} // Enable touch on mobile devices
                  disableFocusListener={!isMobile} // Enable focus-based tooltips on mobile
                >
                  <Box>
                    <i
                      className="fas fa-map-marker-alt"
                      style={{ marginRight: '5px' }}
                    ></i>
                    Address
                  </Box>
                </Tooltip>
              }
              name="address"
              value={address}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  address_id: device?.EntityAddressItem?.Address?.address_id,
                })
              }}
              disabled={true}
            />

            {/* Address Item */}

            <MDInput
              fullWidth
              sx={{ marginTop: '20px' }}
              type="text"
              label={
                <Tooltip
                  title="The specific location or item needing service."
                  placement="right"
                  arrow
                  disableHoverListener={isMobile} // Disable hover on mobile devices
                  disableTouchListener={!isMobile} // Enable touch on mobile devices
                  disableFocusListener={!isMobile} // Enable focus-based tooltips on mobile
                >
                  <Box>
                    <i
                      className="fas fa-location-arrow"
                      style={{ marginRight: '5px' }}
                    ></i>
                    Address Item
                  </Box>
                </Tooltip>
              }
              name="address_item"
              value={device?.EntityAddressItem?.name}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  address_item_id: device?.EntityAddressItem?.address_item_id,
                })
              }}
              disabled={true}
            />

            <Autocomplete
              disabled={serviceRequestEditFlag && uneditableField}
              options={priorities}
              getOptionLabel={(option) => option.priority_name}
              defaultValue={
                serviceRequestEditFlag && serviceRequest?.Priority
                  ? serviceRequest.Priority
                  : priorities[0] || null
              }
              onChange={(event, newValue) => {
                setFormData({
                  ...formData,
                  priority_id: newValue.priority_id,
                })
                setConfirmFormData({
                  ...confirmFormData,
                  priority_name: newValue.priority_name,
                })
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label={
                    <Tooltip
                      title="Select the urgency for the service request."
                      placement="right"
                      arrow
                      disableHoverListener={isMobile} // Disable hover on mobile devices
                      disableTouchListener={!isMobile} // Enable touch on mobile devices
                      disableFocusListener={!isMobile} // Enable focus-based tooltips on mobile
                    >
                      <Box>
                        <i
                          className="fas fa-exclamation-triangle"
                          style={{ marginRight: '5px' }}
                        ></i>
                        Priority
                      </Box>
                    </Tooltip>
                  }
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />

            {errors.priority && (
              <MDTypography sx={{ color: 'red', fontSize: '12px' }}>
                {errors.priority}
              </MDTypography>
            )}

            <Autocomplete
              disabled={serviceRequestEditFlag && uneditableField}
              options={serviceItems}
              getOptionLabel={(option) => option.item_name}
              defaultValue={
                serviceRequestEditFlag && serviceRequest?.ServiceItem
                  ? serviceRequest.ServiceItem
                  : serviceItems[0]
              }
              onChange={(event, newValue) => {
                clickServiceItem(newValue.item_id, newValue.item_name)
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label={
                    <Tooltip
                      title="The type of service being requested."
                      placement="right"
                      arrow
                      disableHoverListener={isMobile} // Disable hover on mobile devices
                      disableTouchListener={!isMobile} // Enable touch on mobile devices
                      disableFocusListener={!isMobile} // Enable focus-based tooltips on mobile
                    >
                      <Box>
                        <i
                          className="fas fa-tools"
                          style={{ marginRight: '5px' }}
                        ></i>
                        Service Item
                      </Box>
                    </Tooltip>
                  }
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />

            {errors.serviceItem && (
              <MDTypography sx={{ color: 'red', fontSize: '12px' }}>
                {errors.serviceItem}
              </MDTypography>
            )}
            {/* Provider */}

            <Autocomplete
              disabled={serviceRequestEditFlag && uneditableField}
              options={providersRelatedToServiceItem || []}
              defaultValue={
                serviceRequestEditFlag && serviceRequest?.Provider
                  ? serviceRequest.Provider
                  : providersRelatedToServiceItem[0] || null
              }
              getOptionLabel={(option) => option?.provider_name}
              onChange={(event, newValue) => {
                setFormData({
                  ...formData,
                  provider_id: newValue ? newValue.provider_id : '',
                })
                setConfirmFormData({
                  ...confirmFormData,
                  provider_name: newValue ? newValue.provider_name : '',
                })
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label={
                    <Tooltip
                      title="The provider responsible for the service."
                      placement="right"
                      arrow
                      disableHoverListener={isMobile} // Disable hover on mobile devices
                      disableTouchListener={!isMobile} // Enable touch on mobile devices
                      disableFocusListener={!isMobile} // Enable focus-based tooltips on mobile
                    >
                      <Box>
                        <i
                          className="fas fa-user-tie"
                          style={{ marginRight: '5px' }}
                        ></i>
                        Provider
                      </Box>
                    </Tooltip>
                  }
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />

            {errors.provider && (
              <MDTypography sx={{ color: 'red', fontSize: '12px' }}>
                {errors.provider}
              </MDTypography>
            )}

            {/* Description */}

            <TextField
              disabled={serviceRequestEditFlag && uneditableField}
              fullWidth
              label={
                <Tooltip
                  title="Additional notes or details for the service request."
                  placement="right"
                  arrow
                  disableHoverListener={isMobile} // Disable hover on mobile devices
                  disableTouchListener={!isMobile} // Enable touch on mobile devices
                  disableFocusListener={!isMobile} // Enable focus-based tooltips on mobile
                >
                  <Box>
                    <i
                      className="fas fa-align-left"
                      style={{ marginRight: '5px' }}
                    ></i>
                    Description
                  </Box>
                </Tooltip>
              }
              sx={{ marginTop: '20px' }}
              defaultValue={serviceRequest?.description}
              value={formData.description}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  description: event.target.value,
                })
                setConfirmFormData({
                  ...confirmFormData,
                  description: event.target.value,
                })
              }}
            />

            {errors.description && (
              <MDTypography sx={{ color: 'red', fontSize: '12px' }}>
                {errors.description}
              </MDTypography>
            )}

            {/* request date */}

            <TextField
              disabled={serviceRequestEditFlag && uneditableField}
              sx={{ width: '100%', marginTop: '30px' }}
              label={
                <Tooltip
                  title="The date you'd like the service to be performed. This is not a confirmed date."
                  placement="right"
                  arrow
                  disableHoverListener={isMobile} // Disable hover on mobile devices
                  disableTouchListener={!isMobile} // Enable touch on mobile devices
                  disableFocusListener={!isMobile} // Enable focus-based tooltips on mobile
                >
                  <Box>
                    <i
                      className="fas fa-calendar-alt"
                      style={{ marginRight: '5px' }}
                    ></i>
                    Requested Date
                  </Box>
                </Tooltip>
              }
              type="date"
              defaultValue={serviceRequest?.request_date}
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: today }}
              value={formData.request_date}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  request_date: event.target.value,
                })
                setConfirmFormData({
                  ...confirmFormData,
                  request_date: event.target.value,
                })
              }}
            />

            {errors.requestDate && (
              <MDTypography sx={{ color: 'red', fontSize: '12px' }}>
                {errors.requestDate}
              </MDTypography>
            )}

            <FormControlLabel
              control={
                <Switch
                  checked={smartChecked}
                  onChange={handleChange}
                  color="secondary"
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: 'white', // Thumb (knob) color when checked
                      '& + .MuiSwitch-track': {
                        backgroundColor: 'blue', // Track (background) color when checked
                      },
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      opacity: 1, // Ensures track visibility
                    },
                    '& .MuiSwitch-track': {
                      backgroundColor: 'lightgray', // Track color when unchecked
                      opacity: 1, // Ensures visibility of track
                    },
                  }}
                />
              }
              label={
                <span style={{ fontSize: '0.8rem', fontWeight: '300' }}>
                  Smart Servcie
                  <Tooltip
                    placement="right"
                    title="Enable Smart Service to automate actions based on specific conditions."
                  >
                    <i className="bi bi-magic"></i>
                  </Tooltip>
                </span>
              }
            />

            {smartChecked && (
              <>
                <Typography>Select Smart Service Criteria</Typography>
                <div style={{ overflowX: 'auto' }}>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr style={{ fontSize: '0.825rem', textAlign: 'left' }}>
                        <th
                          style={{
                            width: '20%',
                            padding: '8px',
                            border: '1px solid #ddd',
                          }}
                        >
                          Select
                        </th>
                        <th
                          style={{
                            width: '30%',
                            padding: '8px',
                            border: '1px solid #ddd',
                          }}
                        >
                          Service Name
                        </th>
                        <th
                          style={{
                            width: '50%',
                            padding: '8px',
                            border: '1px solid #ddd',
                          }}
                        >
                          Description
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {serviceRequestAutoCompletions &&
                        serviceRequestAutoCompletions.map((row) => (
                          <tr key={row.id} style={{ fontSize: '0.8rem' }}>
                            <td
                              style={{
                                width: '20%',
                                textAlign: 'center',
                                padding: '8px',
                                border: '1px solid #ddd',
                              }}
                            >
                              <input
                                type="radio"
                                checked={selectedValue === row.id}
                                onChange={(event) =>
                                  handleRadioChange(event, row.id)
                                }
                                value={row.id}
                              />
                            </td>
                            <td
                              style={{
                                width: '30%',
                                padding: '8px',
                                border: '1px solid #ddd',
                              }}
                            >
                              {row.name}
                            </td>
                            <td
                              style={{
                                width: '50%',
                                padding: '8px',
                                border: '1px solid #ddd',
                              }}
                            >
                              {row.description}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </CardContent>
          <MDBox
            ml="auto"
            sx={{ position: 'absolute', bottom: '10px', right: '10px' }}
            display="flex"
            justifyContent="flex-end"
          >
            <MDButton onClick={() => setServiceModal(false)} color="secondary">
              Close
            </MDButton>

            <Tooltip title="Button to submit the request. Sends the service request to the appropriate provider for action.">
              <MDButton
                onClick={handleSubmit}
                style={{
                  backgroundColor: 'blue',
                  color: 'white',
                  marginLeft: '10px',
                }}
              >
                <i
                  class="fas fa-paper-plane"
                  style={{ marginRight: '5px' }}
                ></i>
                Submit
              </MDButton>
            </Tooltip>
          </MDBox>
        </MDBox>
        <Modal
          open={openServiceRequestConfirmModal}
          onClose={handleServiceModalClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Card
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' }, // Set the desired width here
              height: { xs: '90%', sm: '90%', md: '700px', lg: '700px' },
              backgroundColor: 'white',
              paddingTop: 2,
            }}
          >
            <ServiceRequestConfirmForm
              formData={formData}
              confirmFormData={confirmFormData}
              mapX={mapX}
              mapY={mapY}
              setServiceRequestConfirmModal={setOpenServiceRequestConfirmModal}
              setServiceModal={setServiceModal}
              setServiceRequests={setServiceRequests}
              address_item_id={device?.EntityAddressItem?.address_item_id}
              showToast={showToast}
            />
          </Card>
        </Modal>
      </Container>
    )
  },
)

export default ServiceRequestForm
