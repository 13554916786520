import React, { useState, useEffect } from "react";
import { Container, Typography, CardContent, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CrudService from "services/cruds-service";
import { MODULE_MASTER } from "utils/constant";
import { RULES } from "utils/constant";

// Placeholder for FormField component
const FormField = ({ type, label, name, value, onChange, error, ...props }) => (
  <TextField
    type={type}
    label={label}
    name={name}
    value={value}
    onChange={onChange}
    error={!!error}
    helperText={error}
    fullWidth
    margin="normal"
    {...props}
  />
);

const AlertRulesForm = React.memo(
  ({ alertRule, setAlertRules, deviceId, setOpenViewModal }) => {
    const [formData, setFormData] = useState({
      frequency: alertRule ? alertRule.frequency : "",
      alert_type_id: alertRule ? alertRule.alert_type_id : "",
      service_item_id: alertRule ? alertRule.service_item_id : "",
      alert_sub_type_id: alertRule ? alertRule.alert_sub_type_id : "",
      event_type_id: alertRule ? alertRule.event_type_id : "",
      send_to_type_id: alertRule ? alertRule.send_to_type_id : "",
      address_id: alertRule ? alertRule.address_id : "",
      status_type_id: alertRule ? alertRule.status_type_id : "",
      device_id: alertRule ? alertRule.device_id : deviceId,
      message_template_id: alertRule ? alertRule.message_template_id : "",
      template_id: alertRule ? alertRule.template_id : "",
    });

    const [contacts, setContacts] = useState([]);
    const [originalContacts, setOriginalContacts] = useState([]);

    const [alertTypes, setAlertTypes] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [sendToTypes, setSendToTypes] = useState([]);
    const [alertSubTypes, setAlertSubTypes] = useState([]);
    const [originalAlertSubTypes, setOriginalAlertSubTypes] = useState([]);
    const [entityContacts, setEntityContacts] = useState([]);
    const [originalEntityContacts, setOriginalEntityContacts] = useState([]);
    const [providerContacts, setProviderContacts] = useState([]);
    const [originalProviderContacts, setOriginalProviderContacts] = useState(
      []
    );
    const [statusOptions, setStatusOptions] = useState([]);
    const [smsMessageTemplates, setSMSMessageTemplates] = useState([]);
    const [emailMessageTemplates, setEmailMessageTemplates] = useState([]);
    const [serviceItems, setServiceItems] = useState([]);
    const [disableFrequency, setDisableFrequency] = useState(false);
    const [subTypeName, setSubTypeName] = useState("");

    useEffect(() => {
      const fetchData = async () => {
        try {
          const resAlertTypes = await CrudService.getAlertTypes();
          setAlertTypes(resAlertTypes.data);

          const resAlertSubTypes = await CrudService.getAlertSubTypes();
          setOriginalAlertSubTypes(resAlertSubTypes.data);

          const resEventTypes = await CrudService.getEventTypes();
          setEventTypes(resEventTypes.data);

          const resEmailTemplateTypes =
            await CrudService.getEmailTemplateTypes();
          setEmailMessageTemplates(resEmailTemplateTypes.data);

          const resSendToTypes = await CrudService.getSendToTypes();
          setSendToTypes(resSendToTypes.data);

          const resEntityContacts =
            await CrudService.getEntityContacts(deviceId);
          setEntityContacts(resEntityContacts.data);
          setOriginalEntityContacts(resEntityContacts.data);

          const resProviderContacts =
            await CrudService.getProviderContacts(deviceId);
          setProviderContacts(resProviderContacts.data);
          setOriginalProviderContacts(resProviderContacts.data);

          const resStatus = await CrudService.getStatusTypes(
            MODULE_MASTER.ALERTRULE
          );
          setStatusOptions(resStatus.data.map((item) => item.attributes));

          const resSMSMessage = await CrudService.getMessages();
          setSMSMessageTemplates(resSMSMessage.data);

          const resServiceItems = await CrudService.getServiceItems();
          setServiceItems(resServiceItems.data);

          if (alertRule) {
            console.log(alertRule, "-------alertRule-------");
            let sortedSubTypes = resAlertSubTypes.data.filter(
              (item) => item.alert_type_id === alertRule.alert_type_id
            );
            setAlertSubTypes(sortedSubTypes);
            if (alertRule.send_to_type_id === 3) {
              if (alertRule.event_type_id === 15)
                setContacts(
                  resEntityContacts.data.filter(
                    (item) => item.ContactAddress?.address_type_id === 1
                  )
                );
              else if (alertRule.event_type_id === 14)
                setContacts(
                  resEntityContacts.data.filter(
                    (item) => item.ContactAddress?.address_type_id === 2
                  )
                );
            } else if (alertRule.send_to_type_id === 2) {
              if (alertRule.event_type_id === 15)
                setContacts(
                  resProviderContacts.data.filter(
                    (item) => item.ContactAddress?.address_type_id === 1
                  )
                );
              else if (alertRule.event_type_id === 14)
                setContacts(
                  resProviderContacts.data.filter(
                    (item) => item.ContactAddress?.address_type_id === 2
                  )
                );
            }
          } else {
            // setAlertSubTypes(resAlertSubTypes.data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }, [deviceId]);

    useEffect(() => {
      // Populate contacts based on send_to_type_id
      if (formData.send_to_type_id === 3) {
        if (formData.event_type_id === 15)
          setContacts(
            originalEntityContacts.filter(
              (item) => item.ContactAddress?.address_type_id === 1
            )
          );
        else if (formData.event_type_id === 14)
          setContacts(
            originalEntityContacts.filter(
              (item) => item.ContactAddress?.address_type_id === 2
            )
          );
      } else if (formData.send_to_type_id === 2) {
        if (formData.event_type_id === 15)
          setContacts(
            originalProviderContacts.filter(
              (item) => item.ContactAddress?.address_type_id === 1
            )
          );
        else if (formData.event_type_id === 14)
          setContacts(
            originalProviderContacts.filter(
              (item) => item.ContactAddress?.address_type_id === 2
            )
          );
      }
    }, [formData.send_to_type_id, formData.event_type_id]);

    useEffect(() => {
      if (
        subTypeName == "Create" ||
        subTypeName == "Healed" ||
        subTypeName == "Update"
      ) {
        setDisableFrequency(true);
      } else {
        setDisableFrequency(false);
      }
    }, [formData.alert_sub_type_id]);

    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log(formData, "form");
      if (alertRule) {
        try {
          await CrudService.editAlertRule(formData, alertRule?.rule_id);
          setOpenViewModal(false);
        } catch (err) {
          console.error(err);
        }
      } else {
        try {
          await CrudService.createAlertRule(formData);
          setOpenViewModal(false);
        } catch (err) {
          console.error(err);
        }
      }
      const alertRulesRes = await CrudService.getAlertRules(deviceId);
      setAlertRules(alertRulesRes.data);
      // Handle form submission
    };

    const checkSubType = () => {
      let result = "";
      if (!disableFrequency) {
        if (
          alertRule?.AlertSubType?.sub_type_name == "Create" ||
          alertRule?.AlertSubType?.sub_type_name == "Healed" ||
          alertRule?.AlertSubType?.sub_type_name == "Update"
        ) {
          result = true;
        } else {
          result = false;
        }
      } else {
        result = true;
      }
      if (subTypeName == "Resent") {
        result = false;
      }

      return result;
    };
    return (
      <Container>
        <Typography variant="h4" component="h2" gutterBottom>
          {alertRule ? "Edit Rule" : "Create Rule"}
        </Typography>
        <MDBox component="form" method="POST" onSubmit={handleSubmit}>
          <CardContent
            sx={{
              maxHeight: "70vh", // Set max height to enable scrolling
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Autocomplete
              options={RULES}
              getOptionLabel={(option) => option.rule}
              defaultValue={RULES.find(
                (rule) => rule?.module_id === alertRule?.module_id
              )}
              onChange={(event, newValue) => {
                console.log(newValue.rule);

                if (newValue.module_id == 13) {
                  console.log(
                    originalAlertSubTypes,
                    "=========originalAlertSubTypes"
                  );
                  let sortedSubTypes = originalAlertSubTypes.filter(
                    (item) => item.alert_type_id == 10
                  );

                  setAlertSubTypes(sortedSubTypes);
                }

                if (newValue.rule == "service request") {
                  setFormData({
                    ...formData,
                    alert_type_id: 10,
                    module_id: newValue.module_id,
                  });
                } else {
                  setFormData({
                    ...formData,
                    module_id: newValue.module_id,
                  });
                }
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label="Type"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />

            {(formData.module_id == 12 || alertRule?.module_id == 12) &&
              formData.module_id != 13 && (
                <Autocomplete
                  options={alertTypes}
                  defaultValue={alertRule ? alertRule.AlertType : null}
                  getOptionLabel={(option) => option.type_name}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      alert_type_id: newValue ? newValue.alert_type_id : "",
                    });
                    // let sortedSubTypes = originalAlertSubTypes.filter(item => item.alerty_type_id === newValue.alerty_type_id);
                    let sortedSubTypes = originalAlertSubTypes.filter(
                      (item) => item.alert_type_id === newValue.alert_type_id
                    );
                    setAlertSubTypes(sortedSubTypes);
                  }}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label="Alert Type"
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  )}
                />
              )}

            {(formData.module_id == 13 || alertRule?.module_id == 13) && (
              <Autocomplete
                options={serviceItems}
                defaultValue={alertRule ? alertRule.ServiceItem : null}
                getOptionLabel={(option) => option.item_name}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    service_item_id: newValue ? newValue.item_id : "",
                  });
                }}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label="Service Item Type"
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                )}
              />
            )}

            <Autocomplete
              options={alertSubTypes}
              defaultValue={alertRule ? alertRule?.AlertSubType : null}
              getOptionLabel={(option) => option?.sub_type_name}
              onChange={(event, newValue) => {
                setFormData({
                  ...formData,
                  alert_sub_type_id: newValue
                    ? newValue?.alert_sub_type_id
                    : "",
                });
                setSubTypeName(newValue ? newValue?.sub_type_name : "");
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label="Sub Type"
                  InputLabelProps={{ shrink: true }}
                  required
                />
              )}
            />

            <Autocomplete
              options={eventTypes}
              defaultValue={alertRule ? alertRule.EventType : null}
              getOptionLabel={(option) => option.description}
              onChange={(event, newValue) => {
                setFormData({
                  ...formData,
                  event_type_id: newValue ? newValue.id : "",
                });
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label="Event Type"
                  InputLabelProps={{ shrink: true }}
                  required
                />
              )}
            />

            <Autocomplete
              options={sendToTypes}
              defaultValue={alertRule ? alertRule.SendToType : null}
              getOptionLabel={(option) => option.send_to_type_name}
              onChange={(event, newValue) => {
                setFormData({
                  ...formData,
                  send_to_type_id: newValue ? newValue.send_to_type_id : "",
                });
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label="Send To Type"
                  InputLabelProps={{ shrink: true }}
                  required
                />
              )}
            />
            <Autocomplete
              options={contacts}
              defaultValue={alertRule ? alertRule : null}
              // getOptionLabel={(option) => option.ContactAddress?.contact_address}
              getOptionLabel={(option) =>
                `${option.Contact?.first_name} ${option.Contact?.last_name} - ${option.ContactAddress?.contact_address}`
              }
              onChange={(event, newValue) => {
                setFormData({
                  ...formData,
                  address_id: newValue
                    ? newValue.ContactAddress?.contact_address_id
                    : "",
                });
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label="Contact"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />

            <FormField
              type="text"
              label="Frequency"
              name="frequency"
              defaultValue={alertRule ? alertRule?.frequency : ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  frequency: e.target.value ? e.target.value : "",
                });
              }}
              disabled={checkSubType()}
              required
            />

            <Autocomplete
              options={statusOptions}
              defaultValue={alertRule ? alertRule?.StatusOption : null}
              getOptionLabel={(option) => option?.StatusType?.status_name}
              onChange={(event, newValue) => {
                console.log(newValue, "status");
                setFormData({
                  ...formData,
                  status_type_id: newValue
                    ? newValue?.StatusType?.status_type_id
                    : "",
                });
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label="Status Type"
                  InputLabelProps={{ shrink: true }}
                  required
                />
              )}
            />

            {/* SMS Template type */}
            {formData.event_type_id == 15 && (
              <Autocomplete
                options={smsMessageTemplates}
                defaultValue={alertRule ? alertRule?.MessageTemplate : null}
                getOptionLabel={(option) =>
                  option?.attributes ? option?.attributes?.name : option?.name
                }
                onChange={(event, newValue) => {
                  console.log(newValue, "sms template type");
                  setFormData({
                    ...formData,
                    message_template_id: newValue
                      ? newValue?.attributes?.id
                      : "",
                  });
                }}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label="SMS Message Template Type"
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                )}
              />
            )}

            {/* Email Template type */}
            {formData.event_type_id == 14 && (
              <Autocomplete
                options={emailMessageTemplates}
                defaultValue={alertRule ? alertRule?.SendgridTemplate : null}
                getOptionLabel={(option) =>
                  option?.attributes ? option?.attributes?.name : option?.name
                }
                onChange={(event, newValue) => {
                  console.log(newValue, "email template type");
                  setFormData({
                    ...formData,
                    template_id: newValue ? newValue?.attributes?.id : "",
                  });
                }}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label="Email Message Template Type"
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                )}
              />
            )}
          </CardContent>

          <MDBox ml="auto" mt={3} display="flex" justifyContent="flex-end">
            <MDButton onClick={() => setOpenViewModal(false)} color="secondary">
              Close
            </MDButton>
            <MDButton
              type="submit"
              style={{
                backgroundColor: "blue",
                color: "white",
                marginLeft: "10px",
              }}
            >
              {alertRule ? "Save Alert Rule" : "Add Alert Rule"}
            </MDButton>
          </MDBox>
        </MDBox>
      </Container>
    );
  }
);

export default AlertRulesForm;
