import React, { useState, useEffect } from "react";
import {
    Box,
    Container,
    Typography,
    TextField,
    Grid,
    CardContent,
    Button
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import CrudService from "services/cruds-service";

import GoogleMapComponent from "examples/ServiceRequestFormMap";
import { ToastContainer, toast } from 'react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import moment from 'moment';


// Placeholder for FormField component
const FormField = ({ type, label, name, value, onChange, error, ...props }) => (
    <TextField
        type={type}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        error={!!error}
        helperText={error}
        fullWidth
        margin="normal"
        {...props}
    />
);

const ServiceRequestEditForm = React.memo(
    ({
        service_request_id,
        next_status_id,
        module_id,
        user_id,
        showToast,
        nextStatusName,
        handleCloseEditBtn,
        setServiceStatusUpdate
    }) => {

        const today = new Date().toISOString().split("T")[0];
        const [updateDate, setUpdateDate] = useState(today);
        const [dateError, setDateError] = useState(false);
        const [timePeriodError, setTimePeriodError] = useState(false);
        const [timePeriodOptions, setTimePeriod] = useState([]);
        const [selectedTimePeriod ,setSelectedTimePeriod] = useState(""); 

        const [nextStatusId, setNextStatusId] = useState(next_status_id);
     
        useEffect(()=>{

              (async() => {
                  const timePeriodRes = await CrudService.getTimePeriod();
                  const defaultTimePeriod = timePeriodRes.find(item => item.is_default === 1);
                  console.log(defaultTimePeriod, "============defaultTimePeriod");
                  setSelectedTimePeriod(defaultTimePeriod);

                  setTimePeriod(timePeriodRes);
               })();
        },[]);   

           
          

        const clickNextStatusBtn = async (e) => {

             console.log(selectedTimePeriod);
             console.log(next_status_id);

             // if(!updateDate) {
             //    setDateError(true);
             //    return;
             // }else{
             //    setDateError(false);
             // }

             if(selectedTimePeriod == "" && next_status_id == "14"){
               setTimePeriodError(true);
               return;
             }else{
               setTimePeriodError(false);
             }
             const newServiceRequestInfo = {
                status_type_id: next_status_id,
                service_request_id: service_request_id,
               
              };

            let updatedServiceRequestInfo = {}
            

            switch(next_status_id){
                case "17":
                      updatedServiceRequestInfo = {
                          ...newServiceRequestInfo,
                          acknowledge_date: updateDate
                      }
                      break;
                case  "14":
                     updatedServiceRequestInfo = {
                          ...newServiceRequestInfo,
                          scheduled_date: updateDate,
                          time_period_id: selectedTimePeriod?.time_period_id
                      }
                      break;
                case  "42":
                     updatedServiceRequestInfo = {
                          ...newServiceRequestInfo,
                          accept_date: updateDate
                      }
                      break;    
                case "6" :
                     updatedServiceRequestInfo = {
                          ...newServiceRequestInfo,
                          complete_date: updateDate
                      }
                      break;    
                default:
                      updatedServiceRequestInfo = newServiceRequestInfo;
                      break;
                            
            }

            console.log(updatedServiceRequestInfo);
          
            const changeServiceStatusRes = await CrudService.updateServiceStatus(
                updatedServiceRequestInfo
            );
            if (changeServiceStatusRes) {
                const now = moment().format("YYYY-MM-DD HH:mm:ss");
                setServiceStatusUpdate(now);
                handleCloseEditBtn();
                showToast({
                        status:"201",
                        message:changeServiceStatusRes.data.msg         
                    });
            }
        };

        const clickDeclineBtn = async(e) => {
            if(!updateDate) {
                  setDateError(true);
                  return;
               }else{
                  setDateError(false);
               }
            const newServiceRequestInfo = {
                nextStatusId: 29,
                serviceRequestId: service_request_id,
                userId: user_id,
                reject_date: updateDate,
                moduleId: 13,
                device_params: localStorage.getItem("device_params")
                    ? localStorage.getItem("device_params")
                    : "",
            };

            const changeServiceStatusRes = await CrudService.updateServiceStatus(
                newServiceRequestInfo
            );
            if (changeServiceStatusRes) {
                const now = moment().format("YYYY-MM-DD HH:mm:ss");
                setServiceStatusUpdate(now);
                handleCloseEditBtn();
                showToast({
                        status:"201",
                        message:changeServiceStatusRes.data.msg         
                    });
            }
        }

        return (
            <Container>
               
                <Typography variant="h5" component="h2" gutterBottom sx={{textAlign:"center"}}>
                    <i class="fas fa-thumbs-up" style={{marginBottom:"2px"}} ></i>{nextStatusName} or Decline Service Request
                </Typography>
                <MDBox>
                    <CardContent
                        sx={{
                            maxHeight: "70vh", // Set max height to enable scrolling
                            overflowY: "auto",
                            overflowX: "hidden",
                            padding:"0px"
                        }}
                    >

                    <Box sx={{  marginTop:"10px",width:'100%',display:"flex",flexDirection:"column",alignItems:"center"}}>
                         <Box sx={{display:"flex",width:'100%'}}>
                           <Box sx={{display:"flex", width:"100%",justifyContent:"center"}}>
                       
                              <TextField
                                  type="date"
                                  defaultValue = {today}
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{ min: today }}
                                  sx={{width:"300px"}}
                                  label = "Date"
                                  variant="outlined"
                                  onChange={(event) => {
                                      setUpdateDate(
                                         event.target.value,
                                      );
                                     
                                  }}
                                  required
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                      disableUnderline: true, // Remove the underline
                                      sx: {
                                        padding: '5px', // Remove padding around the input text

                                      },
                                    }}
                                    sx={{
                                      '& input': {
                                        padding: '5px', // Remove padding inside the input
                                        fontSize: '12px',
                                        width:"290px"

                                      },
                                     
                                    }} 
                              />
                            </Box>
                            <Box>
                               {dateError && ( <Typography sx={{fontSize:"16px", color:"red"}}>Date Field is required</Typography>)}
                            </Box>
                          </Box>  
                           
                          {(nextStatusId == 14 && selectedTimePeriod) && (
                            <>
                              <Box sx={{display:"flex", alignItems:{xs:"start",sm:"center"}, gap:{xs:"0px",sm:"10px"}, flexDirection:{xs:"column", sm:"row"}}}>
                                <Box sx={{width:{xs:'303px',sm:"310px"}}}>

                                 <Autocomplete
                                      options={timePeriodOptions}
                                      getOptionLabel={(option) => (option.period_name + " (" + moment(option.start_time,'HH:mm:ss').format('hh:mm A') + " - " + moment(option.end_time,'HH:mm:ss').format('hh:mm A') + ")")}
                                      defaultValue={selectedTimePeriod}
                                      onChange={(event, newValue) => {
                                          setSelectedTimePeriod(newValue);
                                      }}

                                      sx={{width:"100%",  padding: '0px'}}
                                      renderInput={(params) => (
                                          <FormField 
                                              {...params}
                                              label="Time Period"
                                              InputLabelProps={{ shrink: true }}
                                              required
                                              sx={{
                                                padding:'0px',
                                                
                                                '& .MuiInputBase-root': {
                                                  paddingLeft: '5px',  // Adjust the padding here
                                                  paddingTop: '3px',
                                                  paddingBottom: '3px',
                                                  fontSize:'12px'
                                                },
                                              }}
                                          />
                                      )}
                                  />
                                </Box>  
                                
                             
                           </Box>   
                            <Box>
                               {timePeriodError && ( <Typography sx={{fontSize:"16px", color:"red"}}>Time Period is required</Typography>)}
                            </Box>

                          </>  
                        )}
                           
                      
                    </Box>

                     <Box sx={{display:"flex", justifyContent:"center",  marginTop:"20px", gap:"20px"}}>
                         <Button variant="contained" style={{backgroundColor:"#dc3545",fontSize:"14px",color:"white", textTransform: 'none', display:"flex", gap:"5px", alignItems:"center"}} onClick={clickDeclineBtn}><i class="fas fa-times-circle"></i> Decline </Button>
                         <Button variant="contained" style={{backgroundColor:"#28a745",fontSize:"14px",color:"white", textTransform: 'none', display:"flex", gap:"5px", alignItems:"center"}} onClick={clickNextStatusBtn}><i class="fas fa-check-circle"></i> {nextStatusName}</Button>
                         
                     </Box>
                        
                    </CardContent>
                </MDBox>
            </Container>
        );
    }
);

export default ServiceRequestEditForm;
