import React, { useState, useEffect } from "react";
import {
    Container,
    Typography,
    TextField,
    Grid,
    CardContent,
    CardActions
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CrudService from "services/cruds-service";

import GoogleMapComponent from "examples/ServiceRequestFormMap";
import { ToastContainer, toast } from 'react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

// Placeholder for FormField component
const FormField = ({ type, label, name, value, onChange, error, ...props }) => (
    <TextField
        type={type}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        error={!!error}
        helperText={error}
        fullWidth
        margin="normal"
        {...props}
    />
);

const ServiceRequestConfirmForm = React.memo(
    ({
        formData,
        confirmFormData,
        mapX,
        mapY,
        setServiceRequestConfirmModal,
        setServiceModal,
        setServiceRequests,
        address_item_id,
        showToast,
        test
    }) => {

        const [loading, setLoading] = useState(false); // Manage loading state
        const handleSubmit = async (e) => {
            
                if (formData) {
                    setLoading(true); // Set loading to true while submitting
                    let serviceRequestRes;
                    try {
                         serviceRequestRes = await CrudService.createServiceRequest(formData);
                       
                        const serviceRequestssRes = await CrudService.getServiceRequests(address_item_id);
                        setServiceRequests(serviceRequestssRes.data) 
                        if(serviceRequestRes.data.status == "success"){
                             showToast({
                                status:"201",
                                message:serviceRequestRes.data.msg         
                           });
                        }else{
                             showToast({
                                status:"500",
                                message:serviceRequestRes.data.msg           
                            });
                        }
                       

                    } catch (err) {
                       // showToast({
                       //      status:"500",
                       //      message:serviceRequestRes.data.msg           
                       //  });
                    } finally {
                        setLoading(false); // Stop the loading spinner when done
                    }


                        setServiceRequestConfirmModal(false);
                        setServiceModal(false)
                }
          
        };

        return (
            <Container sx={{display:'flex', flexDirection:'column', position:"relative", height:'100%'}}>
                <ToastContainer/>

                <Typography variant="h4" component="h2" gutterBottom>
                    Confirm Your Request
                </Typography>
            
                    <CardContent
                        sx={{
                            
                            overflowY: "auto",
                            overflowX: "hidden",
                            padding:"0px"
                        }}
                    >
                            {/* Entity */}
                            <Grid container>
                                <Grid item sm={3} xs={5}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        Entity:
                                    </MDTypography>
                                </Grid>
                                <Grid item sm={9} xs={7}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        {confirmFormData.entity_name}
                                    </MDTypography>
                                </Grid>
                            </Grid>
                            {/* Address  */}
                            <Grid container sx={{marginTop:"5px"}}>
                                <Grid item sm={3} xs={12}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        Address:
                                    </MDTypography>
                                </Grid>
                                <Grid item sm={9} xs={12}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        {confirmFormData.address_name}
                                    </MDTypography>
                                </Grid>
                            </Grid>
                            {/* Address Item */}
                            <Grid container sx={{marginTop:"5px"}}>
                                <Grid item sm={3} xs={5}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        Address Item:
                                    </MDTypography>
                                </Grid>
                                <Grid item sm={9} xs={7}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        {confirmFormData.address_item_name}
                                    </MDTypography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{marginTop:"5px"}}>
                                <Grid item sm={3} xs={5}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        Service Item:
                                    </MDTypography>
                                </Grid>
                                <Grid item sm={9} xs={7}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        {confirmFormData.service_item_name}
                                    </MDTypography>
                                </Grid>
                            </Grid>

                            {/* Provider */}
                            <Grid container sx={{marginTop:"5px"}}>
                                <Grid item sm={3} xs={5}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        Provider:
                                    </MDTypography>
                                </Grid>
                                <Grid item sm={9} xs={7}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        {confirmFormData.provider_name}
                                    </MDTypography>
                                </Grid>
                            </Grid>

                            {/* Description */}
                            <Grid container sx={{marginTop:"5px"}}>
                                <Grid item sm={3} xs={5}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        Description:
                                    </MDTypography>
                                </Grid>
                                <Grid item sm={9} xs={7}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        {formData.description}
                                    </MDTypography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{marginTop:"5px"}}>
                                <Grid item sm={3} xs={5}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        Requested Date:
                                    </MDTypography>
                                </Grid>
                                <Grid item sm={9} xs={7}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        {formData.request_date}
                                    </MDTypography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{marginTop:"5px"}}>
                                <Grid item sm={3} xs={5}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        Priority:
                                    </MDTypography>
                                </Grid>
                                <Grid item sm={9} xs={7}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        {confirmFormData.priority_name}
                                    </MDTypography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{marginTop:"5px"}}>
                                <Grid item xs={3}>
                                    <MDTypography sx={{ fontSize: "12px" }}>
                                        Map:
                                    </MDTypography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <GoogleMapComponent lat={mapX} lng={mapY} />
                            </Grid>

                        
                    </CardContent>
                    <CardActions
                        sx={{display:'flex', justifyContent:"flex-end", position:'absolute',bottom: '10px', right: '10px'}}
                        >
                            <MDButton
                                onClick={() =>
                                    setServiceRequestConfirmModal(false)
                                }
                                color="secondary"
                            >
                                Close
                            </MDButton>
                            <MDButton
                                style={{
                                    backgroundColor: "blue",
                                    color: "white",
                                    marginLeft: "10px",
                                }}
                                onClick={handleSubmit}
                                disabled={loading} // Disable the button when loading
                            >
                                {loading ? (
                                    <span>
                                    <i className="fa fa-spinner fa-spin"></i> Submitting...
                                    </span>
                                  ) : (
                                    "Confirm"
                                  )}
                            </MDButton>
                        </CardActions>
              
            </Container>
        );
    }
);

export default ServiceRequestConfirmForm;
