import React, { useState, useEffect } from "react";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import { styled } from "@mui/system";
import {
    Container,
    Typography,
    TextField,
    Modal,
    Card,
    CardContent,
    CardHeader,
    Box
} from "@mui/material";
import Badge from "@mui/material/Badge";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CrudService from "services/cruds-service";
import MDEditor from "components/MDEditor";
import { red } from "@mui/material/colors";
import ServiceRequestConfirmForm from "./confirm";
import { RULES } from "utils/constant";

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        whiteSpace: "wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: "auto",
        padding: "6px",
    },
}));

// Placeholder for FormField component
const FormField = ({ type, label, name, value, onChange, error, ...props }) => (
    <TextField
        type={type}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        error={!!error}
        helperText={error}
        fullWidth
        margin="normal"
        {...props}
    />
);

const MultiSeviceRequestStatusEditForm = React.memo(
    ({
        selectedRowsIdArray,
        setMultiStatusEditModal,
        serviceRequestId,
        setDataTableUpdate,
        selectedRequestType,
        showToast,
        totalServiceRequestManagement
    }) => {

        const today = new Date().toISOString().split("T")[0];
        console.log(selectedRequestType, "request type mutli modal");
        const [serviceStatusTypes, setServiceStatusTypes] = useState("");
        const [updatedStatusType, setUpdatedStatusType] = useState("");
        const [updateDate, setUpdateDate] = useState(today);
        const [timePeriodOptions, setTimePeriod] = useState([]);
        const [selectedTimePeriod ,setSelectedTimePeriod] = useState(""); 
        const [formData,setFormData] = useState({
            status_type_id: ""
        });
        const [userId, setUserId] = useState("");

        useEffect(()=>{

              (async() => {
                  const timePeriodRes = await CrudService.getTimePeriod();
                  const defaultTimePeriod = timePeriodRes.find(item => item.is_default === 1);
                  console.log(defaultTimePeriod, "============defaultTimePeriod");
                  setSelectedTimePeriod(defaultTimePeriod);

                  setTimePeriod(timePeriodRes);
               })();
        },[]);   


        useEffect(() => {
            const fetchData = async () => {
                let res = await CrudService.getServiceRequest(serviceRequestId);

                let serviceRequest = res.data;
                console.log(serviceRequest, "=========serviceRequest");
                let module_id =
                    serviceRequest?.attributes?.StatusOption?.module_id;
                let user_id = serviceRequest?.attributes?.user?.id;

                if (module_id == undefined) {
                    return;
                }

                const token = localStorage.getItem("token");

                if (token) {
                    const decoded = jwtDecode(token);
                    const role_id = decoded.role_id;
                    setUserId(decoded.id);
                    const payload = {
                        roleId: role_id,
                        moduleId: module_id,
                        currentStatusId: selectedRequestType,
                    };
                    const statusTypesRes =
                        await CrudService.getNextStatusByCurrentStatus(payload);
                    setServiceStatusTypes(statusTypesRes.data);
                }
            };

            fetchData();
        }, [selectedRowsIdArray]);

        const handleSubmit = async (e) => {
            e.preventDefault();

            let res = await CrudService.getServiceRequest(serviceRequestId);

            let serviceRequest = res.data;
            console.log(serviceRequest, "=========serviceRequest");
            let module_id = serviceRequest?.attributes?.StatusOption?.module_id;
            let user_id = serviceRequest?.attributes?.user?.id;

            const newServiceRequestInfo = {
                status_type_id: updatedStatusType,
              };

            let updatedServiceRequestInfo = {}
            

            switch(updatedStatusType){
                case 17:
                      updatedServiceRequestInfo = {
                          ...newServiceRequestInfo,
                          acknowledge_date: updateDate
                      }
                      break;
                case  14:
                     updatedServiceRequestInfo = {
                          ...newServiceRequestInfo,
                          scheduled_date: updateDate,
                          time_period_id: selectedTimePeriod?.time_period_id
                      }
                      break;
                case  42:
                     updatedServiceRequestInfo = {
                          ...newServiceRequestInfo,
                          accept_date: updateDate
                      }
                      break;    
                case 6 :
                     updatedServiceRequestInfo = {
                          ...newServiceRequestInfo,
                          complete_date: updateDate
                      }
                      break;    
                default:
                      updatedServiceRequestInfo = newServiceRequestInfo;
                      break;
                            
            }

            
            let payload = {
                userId: userId,
                moduleId: module_id,
                selectedRowsIdArray: selectedRowsIdArray,
                updatedStatusType: updatedStatusType,
                totalServiceRequestManagement:totalServiceRequestManagement,
                updatedServiceRequestInfo: updatedServiceRequestInfo
            };
           
            
            const multipleServiceRequestUpdateRes = await CrudService.updateMultiServiceRequestStatusType(payload);

            const now = moment().format("YYYY-MM-DD HH:mm:ss");
            setDataTableUpdate(now);
            setMultiStatusEditModal(false);
            if (multipleServiceRequestUpdateRes.msg){
                 showToast({
                        status:"201",
                        message:multipleServiceRequestUpdateRes.msg         
                    });
            }
            if(multipleServiceRequestUpdateRes.error){
                 showToast({
                        status:"500",
                        message:multipleServiceRequestUpdateRes.error         
                    });
            }
        };

        return (
            <Container sx={{ padding: "0px" }}>
                <MDBox sx={{ height: "50px" }}>
                    <Typography
                        variant="h2"
                        component="h2"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: { xs: "16px",sm:"20px" },
                        }}
                    >
                        Multi Service Request Status Edit
                    </Typography>
                </MDBox>
                <MDBox component="form" method="POST" onSubmit={handleSubmit}>
                    <CardContent
                        sx={{
                            maxHeight: "70vh", // Set max height to enable scrolling
                            overflowY: "auto",
                            overflowX: "hidden",
                            padding: "0px !important",
                        }}
                    >
                        <Autocomplete
                            options={serviceStatusTypes || []}
                            getOptionLabel={(option) =>
                                option?.StatusType?.status_name
                            }
                            onChange={(event, newValue) => {
                                setUpdatedStatusType(
                                    newValue?.StatusType?.status_type_id
                                );
                            }}
                            sx={{width:"100%",  padding: '0px'}}
                            renderInput={(params) => (
                                <FormField
                                    {...params}
                                    label="Service Request Status Type"
                                    InputLabelProps={{ shrink: true }}
                                    required
                                    sx={{
                                        padding:'0px',
                                        
                                        '& .MuiInputBase-root': {
                                          paddingLeft: '5px',  // Adjust the padding here
                                          paddingTop: '2px',
                                          paddingBottom: '2px',
                                          fontSize:'12px'
                                        },
                                    }}
                                />
                            )}

                        />

                        <Box sx={{display:'flex', width:'100%',marginTop:'5px'}}>
                            <TextField
                              type="date"
                              defaultValue = {today}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ min: today }}
                              
                              label = "Date"
                              variant="outlined"
                              onChange={(event) => {
                                  setUpdateDate(
                                     event.target.value,
                                  );
                                 
                              }}
                              required
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                  disableUnderline: true, // Remove the underline
                                  sx: {
                                    padding: '5px', // Remove padding around the input text

                                  },
                                }}
                                sx={{
                                  width:'100%',
                                  '& input': {
                                    padding: '5px', // Remove padding inside the input
                                    fontSize: '12px',
                                  
                                  },
                                 
                                }} 
                               />
                        </Box>

                        

                              {(updatedStatusType == 14) && (
                                    <>
                                      <Box sx={{display:"flex",width:'100%', alignItems:{xs:"start",sm:"center"}, gap:{xs:"0px",sm:"10px"}, flexDirection:{xs:"column", sm:"row"}}}>
                                        <Box sx={{width:'100%'}}>

                                         <Autocomplete
                                              options={timePeriodOptions}
                                              getOptionLabel={(option) => (option.period_name + " (" + moment(option.start_time,'HH:mm:ss').format('hh:mm A') + " - " + moment(option.end_time,'HH:mm:ss').format('hh:mm A') + ")")}
                                              defaultValue={selectedTimePeriod}
                                              onChange={(event, newValue) => {
                                                  setSelectedTimePeriod(newValue);
                                              }}

                                              sx={{width:"100%",  padding: '0px'}}
                                              renderInput={(params) => (
                                                  <FormField 
                                                      {...params}
                                                      label="Time Period"
                                                      InputLabelProps={{ shrink: true }}
                                                      required
                                                      sx={{
                                                        padding:'0px',
                                                        
                                                        '& .MuiInputBase-root': {
                                                          paddingLeft: '5px',  // Adjust the padding here
                                                          paddingTop: '3px',
                                                          paddingBottom: '3px',
                                                          fontSize:'12px'
                                                        },
                                                      }}
                                                  />
                                              )}
                                          />
                                        </Box>  
                                     
                                      </Box>   

                                  </>  
                              )}

                         
                   

                        <MDBox
                            ml="auto"
                            mt={3}
                            display="flex"
                            justifyContent="flex-end"
                        >
                            <MDButton
                                onClick={() => setMultiStatusEditModal(false)}
                                color="secondary"
                            >
                                Close
                            </MDButton>
                            <MDButton
                                // onClick={handleSubmit}
                                type="submit"
                                style={{
                                    backgroundColor: "blue",
                                    color: "white",
                                    marginLeft: "10px",
                                }}
                            >
                                Submit
                            </MDButton>
                        </MDBox>
                    </CardContent>
                </MDBox>
            </Container>
        );
    }
);

export default MultiSeviceRequestStatusEditForm;
