


import React from 'react';
import { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Badge,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Modal
 
} from '@mui/material';
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CrudService from "services/cruds-service";
import { useNavigate, useParams } from "react-router-dom";
import GoogleMapComponent from "examples/ServiceRequestDetailMap"
import moment from "moment";
import ServiceRequestEditForm from "./serviceRequestEditForm"

import { jwtDecode } from "jwt-decode";



const useStyles = makeStyles((theme) => ({
      propertyName: {
        backgroundColor: "#3b3f51",
        borderColor:"#3b3f51 !important",
        color: "#fff !important",
        fontWeight: "bold !important",
        width:"100%"
      },
      propertyValue: {
        backgroundColor: "#f0f0f0",
        color:"#344767 !important",
        fontWeight: "bold !important",
        borderColor:"#f0f0f0 !important",
        width:"100%"
      }
}));
// Styled components
const Header = styled(Box)({
  backgroundColor: '#3b3f51',
  color: '#fff',
  padding: '15px',
  borderRadius: '8px 8px 0 0',
  textAlign: 'center',
  position: 'relative',
});

const StatusBadge = styled(Badge)(({ theme }) => ({
  position: 'absolute',
  top: '15px',
  right: '20px',
  padding: '8px 15px',
  borderRadius: '12px',
  backgroundColor: '#ffc107',
  color: '#fff',
  fontWeight: 'bold',
  fontSize: '14px',
}));

const StatusItem = styled(Box)(({ theme, active }) => ({
  textAlign: 'center',
  flex: 1,
  borderRadius: '8px',
  margin: '5px',
  backgroundColor: active ? '#28a745' : '#f0f0f0',
  color: active ? '#fff' : '#344767',
  fontWeight: "bold !important",
    [theme.breakpoints.down('sm')]: {
      padding: "5px",
      fontSize: "12px",
      marginTop: '2px',
      marginBottom:'0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: "5px",
      fontSize: "10px"

    },
    [theme.breakpoints.down('md')]: {
      padding: "5px",
      minWidth: '80px',
      fontSize: "14px"
    },
    [theme.breakpoints.up('lg')]: {
      padding: "5px",
      minWidth: '100px',
      fontSize: "16px"
    },
}));

const StatusArrow = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: '20px',
  color: '#6c757d',
  margin: '0 5px',
  [theme.breakpoints.down('sm')]: {
     
      margin: "0px",
      fontSize: "2px !important",
      justifyContent: "center !important"
    },
     [theme.breakpoints.down('md')]: {
     
      margin: "0px",
      fontSize: "2px !important",
      justifyContent: "center !important"
    },
}));

const CollapsibleButton = styled(Button)(({ theme }) => ({
 "&:active": {
    backgroundColor: "#f0f0f0 !important",
    color:"#344767 !important"
  },
  "&:hover": {
    backgroundColor: "#f0f0f0 !important",
    color:"#344767 !important"
  },
  padding: '10px',
  width: '100%',
  textAlign: 'left',
  fontSize: '18px',
  fontWeight: 'bold',
  marginTop: '10px',
  borderRadius: '4px',
  justifyContent: 'space-between',
  color:'#344767',
  backgroundColor: '#f0f0f0',
   boxShadow:'0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.2) !important'
}));

const CollapsibleContent = styled(Box)({
  padding: '10px 18px',
  backgroundColor: '#fff',
  borderRadius: '4px',
  border:"1px solid #f0f0f0 !important",

});

const MapFrame = styled('iframe')({
  width: '100%',
  height: '300px',
  border: 0,
  borderRadius: '4px',
  marginBottom: '20px',
});

const Footer = styled(Box)({
  marginTop: '20px',
  textAlign: 'center',
  fontSize: '14px',
  color: '#666',
});



const ServiceRequestDetail = () => {
  const classes = useStyles();

  const [openProvider, setOpenProvider] = React.useState(false);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [openDeviceData, setOpenDeviceData] = React.useState(false);

  const [isNotified, setNotified] = useState(false);

  const { id } = useParams();
  const [serviceRequest,setServiceRequest] = useState({});
  const [device, setDevice] = useState({});
  const [provider, setProvider] = useState("");
  const [mapX, setMapX] = useState("");
  const [mapY, setMapY] = useState("");
  const [paramValue,setParamValue] = useState({});
  const [serviceRequestStatusLog, setServiceRequestStatusLog] = useState([]);
  const [serviceRequestEditForm ,setServiceRequestEditForm] = useState(false);
  const [nextStatusId, setNextStatusId] = useState("");
  const [userId, setUserId] = useState("");
  const [roleId, setRoleId] = useState();
  const [nextStatusInfo, setNextStatus] = useState([]);
  const [nextStatusName,setNextStatusName] = useState("");
  const [serviceStatusUpdate,setServiceStatusUpdate] = useState("");
  const [sendgridWebHook, setSendGridWebHook] = useState("");
  const [twilioWebHook, setTwilioWebHook] = useState("");


  const [
          serviceRequestStatusLogTableData,
          setServiceRequestStatusLogTableData,
      ] = useState([]);

   const [notification, setNotification] = useState({
        value: false,
        text: "",
    });    

  useEffect(()=>{
       if (!id) return;
      (async () => {
          try {
              const serviceRequestRes = await CrudService.getServiceRequest(id);
              setServiceRequest(serviceRequestRes?.data?.attributes);

              const sendgridWebHookRes = await CrudService.getSendGridWebHook(id);
              setSendGridWebHook(sendgridWebHookRes);

              const twilioWebHookRes = await CrudService.getTwilioWebHook(id);
              setTwilioWebHook(twilioWebHookRes);

              const deviceRes = await CrudService.getDeviceView(serviceRequestRes?.data?.attributes?.device_id);
              setDevice(deviceRes?.data?.attributes);
              setProvider(deviceRes?.data?.entityAddressItemProviders[0]?.Provider?.provider_name);
              setMapX(deviceRes?.data?.attributes?.EntityAddressItem?.Address?.latitude);
              setMapY(deviceRes?.data?.attributes?.EntityAddressItem?.Address?.longitude);


              const res = await CrudService.getServiceRequestStatusLog(id);
              setServiceRequestStatusLogTableData(getServiceRequestStatusLogRows(res.data));
              console.log(deviceRes, "================deviceRes")


              const token = localStorage.getItem("token");

              if (token) {
                  const decoded = jwtDecode(token);
                  console.log(
                      decoded,
                      "=================user token=========="
                  );
                  setUserId(decoded.id);
                  const role_id = decoded.role_id;
                  setRoleId(role_id);
                  const current_status_id =
                      serviceRequestRes?.data?.attributes?.status_type_id;

                  const service_request_status_info = {
                      role_id: role_id,
                      current_status_id: current_status_id,
                  };

                  console.log(
                      service_request_status_info,
                      "=========service_request_status_info"
                  );
                  const rolePermissionResponse =
                      await CrudService.getNextRole(
                          service_request_status_info
                      );

                  console.log(
                      rolePermissionResponse,
                      "=========next role "
                  );

                  setNextStatus(rolePermissionResponse.data);

                  if (service_request_status_info.role_id == "6") {
                      setUnEditableField(true);
                  }
                  if (service_request_status_info.role_id == "7") {
                      setUnEditableField(false);
                  }
              }

            

              if (deviceRes?.data?.monitorParameterValues) {
                  const extractedData = deviceRes.data.monitorParameterValues.map(
                      (item) => ({
                          value: item.value?.device_data,
                          data: item.value,
                          id: item.id,
                          created_at: new Date(item.created_at).toISOString(), // Include full date with time
                      })
                  );

                  // Create an object to store the last data entry for each unique date
                  const paramValues = {};
                  extractedData.forEach((item) => {
                      const date = item.created_at.split("T")[0]; // Extract date without time
                      paramValues[date] = item; // Store the data entry for each date, overwriting for duplicated dates
                  });

                  const lastRecord = extractedData.slice(-1)[0];
                  setParamValue(lastRecord?.data);
                  console.log(lastRecord, "========last record");
                  
              }
 
             
          } catch (err) {
              console.error(err);
          }
      })();
  },[serviceStatusUpdate]);

  useEffect(() => {
        if (notification.value === true) {
            let timer = setTimeout(() => {
                setNotification({
                    value: false,
                    text: "",
                });
            }, 5000);
        }
    }, [notification]);

  const capitalizeFirstLetter = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const showToast = (result) => {
      console.log("show toast");
      setNotification({
          value: true,
          text: result.message,
          bgColor: result.status == "201" ? "info" : "error",
      });
  };



  const cardStyle = (index) => ({
      display: "flex",
      flexDirection: "column",
      ...(expandedIndexArray[index] && { minHeight: "100%" }),
  });

  const showNextStatusButton = () => {
      return nextStatusInfo?.map((status) => (
           <Button  id={status.next_status_id} name= {status.ButtonType.label}
              onClick={handleClickEditBtn}  variant="contained" sx={{width:{xs:"120%",sm:"300px"},height:{xs:"50px"}}}  style={{backgroundColor:"#007bff",fontSize:"18px",color:"white", textTransform: 'none', display:"flex",gap:"5px", alignItems:"center"}}><i class="fas fa-thumbs-up" style={{marginBottom:"2px"}} ></i> {status.ButtonType.label} or Decline</Button>
      ));
  };


  const handleExpand = (index) => {
      setExpandedIndexArray((prevArray) =>
          prevArray.map((item, idx) => (idx === index ? !item : item))
      );
  };

  const handleClickEditBtn = (e) => {
      const nextStatusId = e.target.id;
      const nextStatusName = e.target.name;
      setNextStatusId(nextStatusId);
      setNextStatusName(nextStatusName);
      setServiceRequestEditForm(true)
  }

  const handleCloseEditBtn = (e) => {
      setServiceRequestEditForm(false)
  }


  const getServiceRequestStatusLogRows = (info) => {
          let updatedInfo = info.map((row) => {
              return {
                  userName: row?.user?.name,
                  statusType: row?.StatusType.status_name,
                  createdDate: row?.created_at
                     
              };
          });

          return updatedInfo;
      };

 const [expandedIndexArray, setExpandedIndexArray] = useState([
     
      ...Array(4).fill(false),
  ]);      

  return (
      <DashboardLayout>
         <DashboardNavbar />

         {notification.value && (
                <MDAlert
                    color={notification.bgColor}
                    my="20px"
                    sx={{
                        position: "sticky",
                        top: { md: "100px", xs: "50px" },
                        zIndex: "10000",
                    }}
                >
                    <MDTypography variant="body2" color="white">
                        {notification.text || ""}
                    </MDTypography>
                </MDAlert>
            )}
         <Card maxWidth="md" sx={{marginTop:"20px", padding:"10px"}}>
          <Box className="container">
            <Header>
              <Typography variant="h4" sx={{color:"white !important"}}>Service Request #{id}</Typography>
              <StatusBadge sx={{display:{xs:'none',md:'block'}}}>{serviceRequest.StatusOption?.StatusType?.status_name}</StatusBadge>
            </Header>

            <Box display="flex" justifyContent="space-around" flexWrap="wrap" mb={3} sx={{flexDirection:{xs:"column",sm:"column",md:"row"}}}>
              <StatusItem active={serviceRequest.StatusOption?.StatusType?.status_type_id == 11 }>Requested</StatusItem>
              <StatusArrow><Typography sx={{display:{xs:"none",sm:"none",md:"block"}}}>→</Typography></StatusArrow>
              <StatusArrow><Typography sx={{display:{xs:"block",sm:"block",md:"none"}}}>&darr;</Typography></StatusArrow>
              <StatusItem active={serviceRequest.StatusOption?.StatusType?.status_type_id == 17 }>Acknowledged</StatusItem>
              <StatusArrow><Typography sx={{display:{xs:"none",sm:"none",md:"block"}}}>→</Typography></StatusArrow>
              <StatusArrow><Typography sx={{display:{xs:"block",sm:"block",md:"none"}}}>&darr;</Typography></StatusArrow>
              <StatusItem active={serviceRequest.StatusOption?.StatusType?.status_type_id == 14}>Scheduled</StatusItem>
              <StatusArrow><Typography sx={{display:{xs:"none",sm:"none",md:"block"}}}>→</Typography></StatusArrow>
              <StatusArrow><Typography sx={{display:{xs:"block",sm:"block",md:"none"}}}>&darr;</Typography></StatusArrow>
              <StatusItem active={serviceRequest.StatusOption?.StatusType?.status_type_id == 42 }>In Progress</StatusItem>
              <StatusArrow><Typography sx={{display:{xs:"none",sm:"none",md:"block"}}}>→</Typography></StatusArrow>
              <StatusArrow><Typography sx={{display:{xs:"block",sm:"block",md:"none"}}}>&darr;</Typography></StatusArrow>
              <StatusItem active={serviceRequest.StatusOption?.StatusType?.status_type_id == 6 }>Complete</StatusItem>
            </Box>

            <Box className="content">
              <TableContainer component={Paper}>
                <Table sx={{borderCollapse: "collapse"}}>
                  <TableBody >
                    <TableRow sx={{display:"flex"}}>
                      <TableCell className={classes.propertyName}>Request Type</TableCell>
                      <TableCell className={classes.propertyValue}>{serviceRequest?.ServiceItem?.item_name}</TableCell>
                    </TableRow>
                    <TableRow sx={{display:"flex"}}>
                      <TableCell className={classes.propertyName}>Priority</TableCell>
                      <TableCell className={classes.propertyValue}>{serviceRequest?.Priority?.priority_name}</TableCell>
                    </TableRow>
                    <TableRow sx={{display:"flex"}}>
                      <TableCell className={classes.propertyName}>Requested Date</TableCell>
                      <TableCell className={classes.propertyValue}>{moment(serviceRequest?.request_date).format("dddd, MMM D, YYYY")}</TableCell>
                    </TableRow>
                    <TableRow sx={{display:"flex"}}>
                      <TableCell className={classes.propertyName}>Acknowledged Date </TableCell>
                      <TableCell className={classes.propertyValue}>{(serviceRequest?.acknowledge_date != "0000-00-00" && serviceRequest?.acknowledge_date != null) ? moment(serviceRequest?.acknowledge_date).format("dddd, MMM D, YYYY") : "" }</TableCell>
                    </TableRow>
                    <TableRow sx={{display:"flex"}}>
                      <TableCell className={classes.propertyName}>Scheduled Date</TableCell>
                      <TableCell className={classes.propertyValue}>{(serviceRequest?.scheduled_date != "0000-00-00" && serviceRequest?.scheduled_date != null) ? moment(serviceRequest?.scheduled_date).format("dddd, MMM D, YYYY") : "" }</TableCell>
                    </TableRow>
                    <TableRow sx={{display:"flex"}}>
                      <TableCell className={classes.propertyName}>Scheduled Time</TableCell>
                      <TableCell className={classes.propertyValue}>{serviceRequest?.TimePeriod?.start_time ? (serviceRequest?.TimePeriod?.period_name + ":  " + moment(serviceRequest?.TimePeriod?.start_time,'HH:mm:ss').format('hh:mm A') + "-" + moment(serviceRequest?.TimePeriod?.end_time,'HH:mm:ss').format('hh:mm A')) : ""}</TableCell>
                    </TableRow>
                    <TableRow sx={{display:"flex"}}>
                      <TableCell className={classes.propertyName}>Completed Date</TableCell>
                      <TableCell className={classes.propertyValue}>{(serviceRequest?.complete_date != "0000-00-00" && serviceRequest?.complete_date != null) ? moment(serviceRequest?.complete_date).format("dddd, MMM D, YYYY") : ""}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

               <Card sx={{marginTop:"10px"}}>
                <CardContent
                    style={{
                        backgroundColor:
                            "#f0f0f0",
                        borderBottom:
                            "1px solid #e0e0e0",
                        
                    }}
                  
                >
                 <Typography sx={{fontWeight:"bold", color:"#344767"}}>Entity Name</Typography>
        
                 <Typography sx={{fontSize:"16px", color:"#344767"}}>
                     
                    {device?.EntityAddressItem?.Entity?.name ? device?.EntityAddressItem?.Entity?.name : 
                        device?.EntityAddressItem?.Entity?.first_name + " " + device?.EntityAddressItem?.Entity?.last_name}
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{marginTop:"10px"}}>
                <CardContent
                    style={{
                        backgroundColor:
                            "#f0f0f0",
                        borderBottom:
                            "1px solid #e0e0e0",
                        
                    }}
                  
                >
                 <Typography sx={{fontWeight:"bold", color:"#344767"}}>Location</Typography>
        
                 <Typography sx={{fontSize:"16px", color:"#344767"}}>
                      Address:{" "}
                      {
                          device?.EntityAddressItem?.Address?.street_address1
                      }
                      ,{" "}
                      {
                          device
                              ?.EntityAddressItem
                              ?.Address
                              ?.street_address2
                      }{" "}
                      {
                          device
                              ?.EntityAddressItem
                              ?.Address
                              ?.city
                      }
                      ,{" "}
                      {
                          device
                              ?.EntityAddressItem
                              ?.Address
                              ?.state
                      }
                      ,{" "}
                      {
                          device
                              ?.EntityAddressItem
                              ?.Address
                              ?.postal_code
                      }
                      ,{" "}
                      {
                          device
                              ?.EntityAddressItem
                              ?.Address
                              ?.country
                      }
                  </Typography>
                </CardContent>
              </Card>
              <Box  mt={1} mb={2} >
                 <GoogleMapComponent lat={mapX} lng={mapY} />
              </Box>
            </Box>

             <Grid container spacing={1}>
                  {[
                      "Provider Information",
                      "History",
                      "Device Data",
                      "Message Log"
                  ].map((title, index) => (
                      <Grid
                          item
                          xs={12}
                          sm={12}
                          key={index}
                      >
                          <Card
                              style={cardStyle(index)}
                          >
                              {index === 0 && (
                                <CardHeader
                                  title={<><i class="fas fa-briefcase"></i> {title}</>}
                                  style={{
                                      backgroundColor:
                                          "#f0f0f0",
                                      borderBottom:
                                          "1px solid #e0e0e0",
                                      cursor: "pointer",
                                  }}
                                  onClick={() =>
                                      handleExpand(
                                          index
                                      )
                                  }
                              />
                                )}
                              {index === 1 && (
                                <CardHeader
                                  title={<><i class="fas fa-history"></i> {title}</>}
                                  style={{
                                      backgroundColor:
                                          "#f0f0f0",
                                      borderBottom:
                                          "1px solid #e0e0e0",
                                      cursor: "pointer",
                                  }}
                                  onClick={() =>
                                      handleExpand(
                                          index
                                      )
                                  }
                              />
                                )}
                              {index === 2 && (
                                <CardHeader
                                  title={<><i class="fas fa-database"></i> {title}</>}
                                  style={{
                                      backgroundColor:
                                          "#f0f0f0",
                                      borderBottom:
                                          "1px solid #e0e0e0",
                                      cursor: "pointer",
                                  }}
                                  onClick={() =>
                                      handleExpand(
                                          index
                                      )
                                  }
                              />
                                )}
                              {index === 3 && (
                                <CardHeader
                                  title={<><i class="fas fa-envelope-open-text"></i> {title}</>}
                                  style={{
                                      backgroundColor:
                                          "#f0f0f0",
                                      borderBottom:
                                          "1px solid #e0e0e0",
                                      cursor: "pointer",
                                  }}
                                  onClick={() =>
                                      handleExpand(
                                          index
                                      )
                                  }
                              />
                                )}
                              {expandedIndexArray[
                                  index
                              ] && (
                                  <CardContent>
                                      {index ===
                                          0 && (
                                          <>
                                              <MDTypography
                                                  fontSize={
                                                      1
                                                  }
                                              >
                                               {provider}
                                               </MDTypography> 
                                          </>
                                      )}
                                      {index ===
                                          1 && (
                                          <>
                                              <Typography style={{ fontSize: "1rem", color:"#344767", fontWeight:"400",lineHeight:"1 !important",display:"inline"}}>Created : </Typography>
                                              <Typography style={{ fontSize: "1rem", color:"#344767", fontWeight:"400",lineHeight:"1 !important",display:"inline"}}>{moment(serviceRequest?.created_at).format(
                                                    "dddd, MMM D, YYYY,h:mm:ss A")}</Typography>
                                              <Typography style={{ fontSize: "1rem", color:"#344767", fontWeight:"400",lineHeight:"1 !important",display:"inline"}}> by  {serviceRequest?.user?.name}</Typography>
                                              {serviceRequestStatusLogTableData.map((row, index) => (
                                                 <Typography key={index} style={{ fontSize: "1rem", color:"#344767", fontWeight:"400",lineHeight:"1 !important"}}>
                                                    {row?.statusType} : {moment(row?.createdDate).format("dddd, MMM D, YYYY,h:mm:ss A")} by  {row?.userName}   
                                                 </Typography>
                                              ))}
                                          </>
                                      )}

                                      {index ===
                                          2 && (
                                          <>
                                              <Typography sx={{fontSize:"16px",color:"#344767"}}><i class="fas fa-tint"></i>  Fill Level: {paramValue?.device_data?.percent_level}%</Typography>
                                              <Typography sx={{fontSize:"16px",color:"#344767"}}><i class="fas fa-flask"></i>  Volume: {paramValue?.device_data?.volume_level} gallons</Typography>
                                              <Typography sx={{fontSize:"16px",color:"#344767"}}><i class="fas fa-water"></i> Fluid Level: {paramValue?.device_data?.inch_level} Inches</Typography>
                                              <Typography sx={{fontSize:"16px",color:"#344767"}}><i class="fas fa-battery-three-quarters"></i> Battery Status: {paramValue?.device_data?.battery_status}</Typography>
                                              <Typography sx={{fontSize:"16px",color:"#344767"}}><i class="fas fa-clock"></i> Last Reported: {paramValue?.reported}</Typography>
                                          </>
                                      )}
                                       {index ===
                                          3 && (
                                          <Box sx={{width:"100%",overflowX:"auto"}}>
                                               <table style ={{width:"100%",minWidth:"650px", marginTop:"10px",textAlign:"left",borderCollapse:"collapse"}}>
                                                <tbody><tr style={{backgroundColor:"#3b3f51", color:"white", fontSize:"18px"}}>
                                                    <th style={{padding:"10px"}}><i class="fas fa-comment-dots"></i> Message Type</th>
                                                    <th><i class="fas fa-address-book"></i> Sent To</th>
                                                    <th><i class="fas fa-clock"></i> Sent At</th>
                                                    <th><i class="fas fa-shipping-fast"></i> Delivery Status</th>
                                                    <th><i class="fas fa-eye"></i> Opened</th>
                                                </tr>
                                                <tr style={{fontSize:"16px",color:"#344767",borderBottom:"1px solid #ddd",padding:"10px"}}>
                                                    <td style={{padding:"10px"}}>Email</td>
                                                    <td>{sendgridWebHook?.sent_to ? sendgridWebHook?.sent_to : ""}</td>
                                                    <td>{sendgridWebHook?.created_at ? moment(sendgridWebHook.created_at).format("dddd, MMM D, YYYY HH:mm:ss") : ""}</td>
                                                    <td>{capitalizeFirstLetter(sendgridWebHook?.deliver_status)}</td>
                                                    <td>{(sendgridWebHook?.is_open == "open" && sendgridWebHook?.created_at != null) ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr style={{fontSize:"16px", borderBottom:"1px solid #ddd", color:"#344767"}}>
                                                    <td style={{padding:"10px"}}>SMS</td>
                                                    <td>{twilioWebHook?.to ? twilioWebHook?.to : ""}</td>
                                                    <td>{twilioWebHook?.dateSent ? moment(twilioWebHook?.dateSent).format("dddd, MMM D, YYYY HH:mm:ss") : ""}</td>
                                                    <td>{capitalizeFirstLetter(twilioWebHook?.status)}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody></table>
                                          </Box>
                                      )}

                                 
                                  </CardContent>
                              )}
                          </Card>
                      </Grid>
                  ))}
              </Grid>

               <Box sx={{marginTop:"20px", width:"100%", display:"flex", flexDirection:{xs:"column",sm:"row"},justifyContent:"center",alignItems:"center", position:"sticky", bottom:'0'}}>
                    {nextStatusInfo && showNextStatusButton()}
                </Box>    
            
          </Box>
         </Card>

          <Modal
                    open={serviceRequestEditForm}
                    onClose={handleCloseEditBtn}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Card
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: {xs:"90%", sm:"40%"}, // Set the desired width here
                            backgroundColor: "white",
                            paddingTop: 2,
                        }}
                    >
                        <ServiceRequestEditForm
                            service_request_id = {id}
                            next_status_id = {nextStatusId}
                            module_id = {13}
                            user_id = {userId}
                            showToast = {showToast}
                            nextStatusName = {nextStatusName}
                            handleCloseEditBtn = {handleCloseEditBtn}
                            setServiceStatusUpdate = {setServiceStatusUpdate}

                        />
                    </Card>
                </Modal>

     </DashboardLayout>
  );
};

export default ServiceRequestDetail;
