export const MODULE_MASTER = {
    ORGANIZATIONS: 1,
    COMPANIES: 2,
    ENTITIES: 3,
    ADDRESS: 4,
    ITEMS: 5,
    DEVICES: 8,
    CONTACTS: 9,
    ALERTRULE: 12,
};

export const ADDRESS_TYPES = {
    PHONE: 1,
    EMAIL: 2,
    FAX: 3,
};

export const RULES = [
    { rule: "alert", module_id: 12 },
    { rule: "service request", module_id: 13 },
];

export const MESSAGE_TEMPLATE_VARIABLES = [
    "ORGANIZATION NAME",
    "COMPANY NAME",
    "ENTITY NAME",
    "ENTITY FIRSTNAME",
    "ENTITY LASTNAME",
    "ENTITY TYPE",
    "ENTITY ADDRESS ITEM",
    "ENTITY ADDRESS NAME",
    "SERVICE ITEM NAME",
    "STATUS NAME",
    "BATTERY STATUS",
    "BATTERY VOLTAGE",
    "FLUID LEVEL",
    "PERCENT LEVEL",
    "VOLUME LEVEL",
    "TEMPERATURE",
    "ITEM NAME",
    "ITEM TYPE",
    "DEVICE NAME",
    "DEVICE TYPE",
    "DEVICE BRAND",
    "DEVICE MODEL",
    "DEVICE MONITOR STATUS",
    "DEVICE MONITOR NAME",
    "DEVICE MONITOR FREQUENCY",
    "DEVICE MONITOR TIME UNIT",
    "ALERT VALUE",
    "THRESHOLD VALUE",
    "REQUEST DATE",
    "VIEW REQUEST URL",
];
