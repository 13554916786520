import React, { useState } from 'react'
import { Card, CardContent, Modal } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import FormField from 'layouts/applications/wizard/components/FormField'
import DataTable from 'examples/Tables/DataTable'
import { ToastContainer, toast } from 'react-toastify';

function AddIdentifier({ identifer, identiferItem, setIdentifierItem }) {

    const [open, setOpen] = useState(false);
    const [identiferAddItem, setIdentifierAddItem] = useState(identiferItem)

    function submitHandler(e) {
        e.preventDefault();
        if(identiferItem.filter((e) => e.error === true || e.text == '').length){
            identiferItem.filter((e) => {
                if(e.error === true && e.textError !== ''){
                    toast.error(e.textError)
                }
                if(e.text == ''){
                    toast.error(`${e.name} is required!`)
                }
            })
            return
        }

        setIdentifierAddItem(identiferItem)
        setOpen(false)
    }

    function onChangehandler(value, i){
        let regex = new RegExp(identiferItem[i].regex)
        identiferItem[i].text = value
        if(!regex.test(value)){
            identiferItem[i].error = true
            identiferItem[i].textError = `Invalid ${identiferItem[i].name}`
        } else {
            identiferItem[i].error = false
            identiferItem[i].textError = ''
        }
        setIdentifierItem(() => [...identiferItem])
    }

    const getRows = (info) => {
        let updatedInfo = info.map((row, index) => {
    
            return {
                type: "identifierItems",
                id: index,
                name: row.name,
                value: row.text
            };
        });
        return updatedInfo;
      };

    const dataTableData = {
        columns: [
          { Header: "Name", accessor: "name", width: "10%" },
          { Header: "Value", accessor: "value", width: "10%" },
        ],
    
        rows: getRows(identiferItem),
      };

    return (
        <Card style={{ marginTop: '1rem' }}>
            <ToastContainer />
            <MDBox display="flex" flexDirection="column" px={3} my={2}>
                <MDBox display="flex" flexDirection="column" style={{ marginTop: "1rem" }}>
                    <MDBox
                        display="flex"
                    >
                        <MDTypography variant="h5" color="secondary" fontWeight="bold">
                            Identifier
                        </MDTypography>
                        <MDButton variant="gradient" color="dark" size="small" style={{ marginLeft: "1rem" }} onClick={() => setOpen(true)} >Add Indentifier</MDButton>
                    </MDBox>
                    <Modal
                        open={open}
                        onClose={() => setOpen(false)}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <Card
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '40%', // Set the desired width here
                                backgroundColor: 'white',
                                border: '2px solid #000',
                                boxShadow: 24,
                                p: 4,
                            }}
                        >
                            <CardContent>
                                <MDBox component="form" method="POST">

                                    {
                                        identifer.map((e, i) => (
                                            <MDBox p={1} key={i} >
                                                <FormField
                                                    type="text"
                                                    label={e.DeviceIdentifierType.identifier_type_name}
                                                    name={e.DeviceIdentifierType.identifier_type_name}
                                                    value={identiferItem[i]?.text || ""}
                                                    onChange={(e) => onChangehandler(e.target.value, i)}
                                                    error={identiferItem[i]?.error}
                                                    required={e.is_required}
                                                />
                                                {identiferItem[i]?.error && (
                                                    <MDTypography variant="caption" color="error" fontWeight="light">
                                                        {identiferItem[i]?.textError}
                                                    </MDTypography>
                                                )}
                                            </MDBox>
                                        ))
                                    }

                                    <MDBox ml="auto" mt={3} display="flex" justifyContent="flex-end">
                                        <MDButton variant="contained" color="secondary" onClick={() => setOpen(false)}>
                                            Cancel
                                        </MDButton>
                                        <MDButton variant="contained" color="info" type="submit" style={{ marginLeft: '10px' }} onClick={(e) => submitHandler(e)}>
                                            Save
                                        </MDButton>
                                    </MDBox>
                                </MDBox>
                            </CardContent>
                        </Card>
                    </Modal>
                </MDBox>
                <MDBox>
                    {identiferAddItem.length > 0 && (
                <DataTable
                  table={dataTableData}
                  canSearch={false}
                />
              )}
                </MDBox>
            </MDBox>
        </Card>
    )
}

export default AddIdentifier