/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import {
    Paper,
    Modal,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Tooltip,
    Badge,
    Avatar,
  } from '@mui/material'
  
  // Material Dashboard 2 PRO React components
  import MDBox from 'components/MDBox'
  import MDTypography from 'components/MDTypography'
  import MDAlert from 'components/MDAlert'
  
  // Material Dashboard 2 PRO React examples
  import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
  import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
  import Footer from 'examples/Footer'
  import ServiceRequestDataTable from 'examples/Tables/ServiceRequestDataTable'
  import MDButton from 'components/MDButton'
  
  import DeleteIcon from '@mui/icons-material/Delete'
  import EditIcon from '@mui/icons-material/Edit'
  import SubjectIcon from '@mui/icons-material/Subject'
  
  import CrudService from 'services/cruds-service'
  import { useState, useEffect } from 'react'
  import { useNavigate, useLocation } from 'react-router-dom'
  import { AbilityContext, Can } from 'Can'
  import { useAbility } from '@casl/react'
  import { styled } from '@mui/system'
  import { withStyles } from '@material-ui/core'
  import getId from 'services/helper-service'
  import ServiceRequestsForm from '../device-management/internal-device-management/view-device/service-request/new'
  import MultiSeviceRequestStatusEditForm from '../device-management/internal-device-management/view-device/service-request/multiStatusEdit'
  
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      whiteSpace: 'wrap',
      overflow: 'hidden',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      height: 'auto',
      padding: '6px',
    },
  }))
  
  const OnomondoManagement = () => {
    let { state } = useLocation()
  
    const navigate = useNavigate()
    const ability = useAbility(AbilityContext)
    const [serviceRequestTableData, setServiceRequestTableData] = useState([])
    const [serviceRequests, setServiceRequests] = useState([])
    const [openServiceModal, setServiceModal] = useState(false)
    const [device, setDevice] = useState()
    const [serviceRequestEditFlag, setServiceRequestEditFlag] = useState(false)
    const [serviceRequest, setServiceRequest] = useState([])
    const [serviceStatusUpdate, setServiceStatusUpdate] = useState('')
    const [serviceRequestId, setServiceRequestId] = useState('')
    const [dataTableUpdate, setDataTableUpdate] = useState('')
  
    const [checkRowSelectedRowsFlag, setCheckSelectedRowsFlag] = useState(false)
    const [selectedRowsIdArray, setSelectedRowsIdArray] = useState([])
    const [multiStatusEditModal, setMultiStatusEditModal] = useState(false)
    const [serviceRequestLogModal, setServiceRequestLogModal] = useState(false)
    const [selectedRequestType, setSelectedRequestType] = useState('')
    const [serviceRequestDeleteFlag, setServiceRequestDeleteFlag] = useState(
      false,
    )
  
    const [selectedRows, setSelectedRows] = useState({})
    const [notification, setNotification] = useState({
      value: false,
      text: '',
    })
  
    useEffect(() => {
      ;(async () => {
        const serviceRequestssRes = await CrudService.getServiceRequests()
        setServiceRequests(serviceRequestssRes.data)
      })()
      document.title = `Rivio | Service Requests`
      setSelectedRows({})
    }, [dataTableUpdate, serviceStatusUpdate])
  
    useEffect(() => {
      checkRowSelected()
    }, [selectedRows])
  
    useEffect(() => {
      setServiceRequestTableData(getServiceRequestRows(serviceRequests))
    }, [serviceRequests])
  
    useEffect(() => {
      if (!state) return
      setNotification({
        value: state.value,
        text: state.text,
      })
    }, [state])
  
    useEffect(() => {
      if (notification.value === true) {
        let timer = setTimeout(() => {
          setNotification({
            value: false,
            text: '',
          })
        }, 5000)
      }
    }, [notification])
  
    const showToast = (result) => {
      console.log('show toast')
      setNotification({
        value: true,
        text: result.message,
        bgColor: result.status == '201' ? 'info' : 'error',
      })
    }
  
    const clickServiceRequestViewHandler = (e, service_request_id) => {
      e.stopPropagation()
      navigate(`/service-request-detail/${service_request_id}`)
    }
  
    const clickServiceRequestDeleteHandler = async (e, service_request_id) => {
      try {
        if (!confirm('Are you sure you want to delete this service request?')) {
          e.nativeEvent.stopImmediatePropagation()
        } else {
          console.log(service_request_id, '=========service_request_id==========')
          await CrudService.deleteServiceRequest(service_request_id)
          // the delete does not send a response
          // so I need to get again the categories to set it and this way the table gets updated -> it goes to the useEffect with data dependecy
          const response = await CrudService.getServiceRequests()
          setServiceRequests(response.data)
          setNotification({
            value: true,
            text: 'The service request has been successfully deleted',
          })
        }
      } catch (err) {
        // it sends error is the item is associated with an item
        console.error(err)
        if (err.hasOwnProperty('errors')) {
          setNotification({
            value: true,
            text: err.errors[0].detail,
          })
        }
        return null
      }
    }
  
    const clickStatusEdit = (e) => {
      setMultiStatusEditModal(true)
    }
  
    const clickCancelSelection = (e) => {
      setSelectedRows([])
    }
  
    const checkRowSelected = () => {
      let result = false
      let _selectedRowIdArray = []
  
      for (const key in selectedRows) {
        if (selectedRows?.[key] == true) {
          result = true
          _selectedRowIdArray.push(key)
        }
      }
      setSelectedRowsIdArray(_selectedRowIdArray)
      setCheckSelectedRowsFlag(result)
    }
  
    const clickServiceRequestEditHandler = async (e, service_request_id) => {
      e.stopPropagation()
      setServiceRequestEditFlag(true)
      let res = await CrudService.getServiceRequest(service_request_id)
      console.log(res.data?.attributes, '=============edit service request info')
      const device_id = res.data?.attributes?.device_id
      console.log(device_id, '==========device_id')
      if (device_id) {
        const deviceRes = await CrudService.getDeviceView(device_id)
        console.log(deviceRes?.data?.attributes, 'device data')
        setDevice(deviceRes?.data)
      }
  
      // setDevice(deviceRes?.data);
      setServiceRequest(res.data.attributes)
      setServiceModal(true)
    }
    const handleServiceModalClose = () => {
      setServiceModal(false)
    }
  
    const handleCloseMultiStatusEditModal = () => {
      setMultiStatusEditModal(false)
    }
  
    const getKeyWithSpecialValue = (obj, specialValue) => {
      const entry = Object.entries(obj).find(
        ([key, value]) => value === specialValue,
      )
      return entry ? entry[0] : undefined
    }
  
    const getServiceRequestRows = (info) => {
      let updatedInfo = info.map((row) => {
        return {
          id: row.attributes?.service_request_id,
          user: row.attributes?.user?.name,
          entityName: row.attributes?.EntityAddressItem?.Entity.name
            ? row.attributes?.EntityAddressItem?.Entity.name || ''
            : (row.attributes?.EntityAddressItem?.Entity.first_name || '') +
              ' ' +
              (row.attributes?.EntityAddressItem?.Entity.last_name || ''),
          addressName:
            (row.attributes?.EntityAddressItem?.Address?.street_address1 || '') +
            (row.attributes?.EntityAddressItem?.Address?.street_addresss || '') +
            ' ' +
            (row.attributes?.EntityAddressItem?.Address?.country || '') +
            ' ' +
            (row.attributes?.EntityAddressItem?.Address?.city || '') +
            ' ' +
            (row.attributes?.EntityAddressItem?.Address?.state || ''),
          entityAddressItem: row.attributes?.EntityAddressItem?.name,
          provider: row.attributes?.Provider?.provider_name,
          serviceItem: row.attributes?.ServiceItem?.item_name,
          request_date: row.attributes?.request_date,
          status: (
            <StyledBadge
              color={
                row.attributes?.StatusOption?.BadgeDetail?.color || 'primary'
              }
              variant={row.attributes?.StatusOption?.BadgeDetail?.variant}
              badgeContent={row.attributes?.StatusOption?.StatusType?.status_name}
              style={{ marginLeft: '30px' }}
            ></StyledBadge>
          ),
        }
      })
  
      return updatedInfo
    }
  
    const clickServiceReqeustRowHandler = (service_request_id) => {
      setServiceRequestId(service_request_id)
    }
  
    const handleSelectedRows = (request_id, flag) => {
      if (
        serviceRequestEditFlag != true ||
        serviceRequestLogModal != true ||
        serviceRequestDeleteFlag != true
      ) {
        const firstSelectedServiceRequestId = getKeyWithSpecialValue(
          selectedRows,
          true,
        )
  
        let serviceRequestType = ''
        if (firstSelectedServiceRequestId != undefined) {
          const selectedFirstRequest = serviceRequests.filter((request) => {
            return (
              request?.attributes?.service_request_id ==
              firstSelectedServiceRequestId
            )
          })
          if (selectedFirstRequest) {
            const selectedRequestTypeTemp =
              selectedFirstRequest[0]?.attributes.status_type_id
            serviceRequestType = selectedRequestTypeTemp
  
            setSelectedRequestType(selectedRequestTypeTemp)
          }
        } else {
          const firstRequest = serviceRequests.filter((request) => {
            return request?.attributes?.service_request_id == request_id
          })
  
          const firstRequestType = firstRequest[0]?.attributes?.status_type_id
          serviceRequestType = firstRequestType
        }
  
        const selectedServiceRequest = serviceRequests.filter((request) => {
          return request?.attributes?.service_request_id == request_id
        })
  
        if (selectedServiceRequest) {
          if (
            selectedServiceRequest[0]?.attributes?.status_type_id ==
            serviceRequestType
          ) {
            setSelectedRequestType(serviceRequestType)
            setSelectedRows({
              ...selectedRows,
              [request_id]: flag,
            })
          }
        }
      }
    }
  
    const serviceRequestdataTableData = {
      columns: [
        { Header: 'SIM ID', accessor: 'id' },
        { Header: 'ICCID', accessor: 'status' },
        { Header: 'Label', accessor: 'entityName' },
        { Header: 'Network Whitelist', accessor: 'addressName' },
        { Header: 'IMEI Lock', accessor: 'entityAddressItem' },
        { Header: 'Connector', accessor: 'provider' },
        { Header: ' Activation Status', accessor: 'serviceItem' },
        { Header: 'Last Online Time ', accessor: 'request_date' },
        { Header: 'IP Address', accessor: '' },
        { Header: 'Usage Information', accessor: '' },
        { Header: 'Tags', accessor: '' },
        {
          Header: 'actions',
          disableSortBy: true,
          accessor: '',
          Cell: (info) => {
            return (
              <MDBox display="flex" alignItems="center">
                <Tooltip title="View Service Request">
                  <IconButton
                    onClick={(e) =>
                      clickServiceRequestViewHandler(e, info.cell.row.original.id)
                    }
                  >
                    <MDTypography>
                      <SubjectIcon />
                    </MDTypography>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit Service Request">
                  <IconButton
                    onClick={(e) =>
                      clickServiceRequestEditHandler(e, info.cell.row.original.id)
                    }
                  >
                    <MDTypography>
                      <EditIcon />
                    </MDTypography>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Service Request">
                  <IconButton
                    onClick={(e) =>
                      clickServiceRequestDeleteHandler(
                        e,
                        info.cell.row.original.id,
                      )
                    }
                  >
                    <MDTypography>
                      <DeleteIcon />
                    </MDTypography>
                  </IconButton>
                </Tooltip>
              </MDBox>
            )
          },
        },
      ],
      rows: [], // Assuming tableData is your original data array
    }
  
    return (
      <DashboardLayout>
        <DashboardNavbar />
        {notification.value && (
          <MDAlert
            color={notification.bgColor}
            my="20px"
            sx={{
              position: 'sticky',
              top: { md: '100px', xs: '50px' },
              zIndex: '10000',
            }}
          >
            <MDTypography variant="body2" color="white">
              {notification.text || ''}
            </MDTypography>
          </MDAlert>
        )}
  
        <MDBox pt={6} pb={3}>
          <MDBox mb={3}>
            <Card>
              <MDBox
                p={3}
                lineHeight={1}
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h5" fontWeight="medium">
                Sim Card Management
                </MDTypography>
              </MDBox>
              <MDBox sx={{ marginLeft: '20px' }}>
                {checkRowSelectedRowsFlag && (
                  <MDBox>
                    <MDButton
                      variant="gradient"
                      color="primary"
                      size="small"
                      px={2}
                      my={2}
                      mx={2}
                      onClick={clickStatusEdit}
                    >
                      Edit
                    </MDButton>
  
                    <MDButton
                      variant="gradient"
                      color="secondary"
                      size="small"
                      px={2}
                      my={2}
                      sx={{ marginLeft: '10px' }}
                      onClick={clickCancelSelection}
                    >
                      Cancel Selection
                    </MDButton>
                  </MDBox>
                )}
              </MDBox>
  
              <ServiceRequestDataTable
                table={serviceRequestdataTableData}
                canSearch={true}
                onRowClick={clickServiceReqeustRowHandler}
                handleSelectedRows={handleSelectedRows}
                selectedRows={selectedRows}
                setDataTableUpdate={setDataTableUpdate}
              />
            </Card>
          </MDBox>
        </MDBox>
  
        {/* multi status edit modal */}
        <Modal
          open={multiStatusEditModal}
          onClose={handleCloseMultiStatusEditModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Card
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%', // Set the desired width here
              backgroundColor: 'white',
              p: 4,
            }}
          >
            <MultiSeviceRequestStatusEditForm
              selectedRowsIdArray={selectedRowsIdArray}
              setMultiStatusEditModal={setMultiStatusEditModal}
              serviceRequestId={serviceRequestId}
              setDataTableUpdate={setDataTableUpdate}
              selectedRequestType={selectedRequestType}
              showToast={showToast}
              totalServiceRequestManagement={true}
            />
          </Card>
        </Modal>
  
        <Modal
          open={openServiceModal}
          onClose={handleServiceModalClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Card
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%', // Set the desired width here
              backgroundColor: 'white',
              p: 4,
            }}
          >
            <ServiceRequestsForm
              device={device?.attributes}
              serviceRequestEditFlag={serviceRequestEditFlag}
              serviceRequest={serviceRequest}
              setServiceModal={setServiceModal}
              setServiceRequests={setServiceRequests}
              setServiceStatusUpdate={setServiceStatusUpdate}
              showToast={showToast}
            />
          </Card>
        </Modal>
        <Footer />
      </DashboardLayout>
    )
  }
  
  export default OnomondoManagement
  