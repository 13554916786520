import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { styled } from "@mui/system";
import moment from "moment";
import {
    Container,
    Typography,
    TextField,
    Modal,
    Card,
    CardContent,
    CardHeader,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CrudService from "services/cruds-service";

import DataTable from "examples/Tables/DataTable";
import crudsService from "services/cruds-service";

const ServiceRequestStatusLogForm = React.memo(
    ({ setServiceRequestLogModal,logServiceRequestId }) => {
        const [
            serviceRequestStatusLogTableData,
            setServiceRequestStatusLogTableData,
        ] = useState([]);
        const [serviceRequestStatusLog, setServiceRequestStatusLog] = useState(
            []
        );

        useEffect(() => {
            const fetchData = async () => {
                try {
                    console.log(logServiceRequestId,"==================logServiceRequestId")
                    const res = await crudsService.getServiceRequestStatusLog(logServiceRequestId);
                    setServiceRequestStatusLog(res.data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };

            fetchData();
        }, []);

        useEffect(() => {
            setServiceRequestStatusLogTableData(
                getServiceRequestStatusLogRows(serviceRequestStatusLog)
            );
        }, [serviceRequestStatusLog]);

        const getServiceRequestStatusLogRows = (info) => {
            let updatedInfo = info.map((row) => {
                return {
                    id: row?.log_id,
                    moduleName: row?.ModuleMaster?.table_name,
                    userName: row?.user?.name,
                    statusType: row?.StatusType.status_name,
                    description: row?.description || "",
                    createdDate: moment(row?.created_at).format(
                        "MMMM DD, YYYY h:mm:ss A"
                    ),
                };
            });

            return updatedInfo;
        };
        const serviceRequestStatusLogDataTable = {
            columns: [
                { Header: "ID", accessor: "id" },
                { Header: "Module Name", accessor: "moduleName" },
                {
                    Header: "User Name",
                    accessor: "userName",
                    width: "20%",
                },
                {
                    Header: "Status Type",
                    accessor: "statusType",
                    width: "20%",
                },

                {
                    Header: "Description",
                    accessor: "description",
                    width: "15%",
                },
                {
                    Header: "Created Date",
                    accessor: "createdDate",
                    width: "15%",
                },
            ],
            rows: serviceRequestStatusLogTableData, // Assuming tableData is your original data array
        };

        return (
            <Container>
                <Typography
                    variant="h4"
                    component="h2"
                    sx={{ display: "flex", alignItems: "center" }}
                >
                    Service Request Status Log
                </Typography>
                <MDBox component="form">
                    <CardContent
                        sx={{
                            maxHeight: "70vh", // Set max height to enable scrolling
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}
                    >
                        <DataTable
                            table={serviceRequestStatusLogDataTable}
                            canSearch={true}
                        />
                    </CardContent>
                </MDBox>
            </Container>
        );
    }
);

export default ServiceRequestStatusLogForm;
