/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Paper,
  Modal,
  CardContent,
  CardHeader,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tab,
  Tabs,
  Tooltip,
  IconButton,
  Avatar,
  Box,
  Typography,
  Stack,
} from "@mui/material";

import Badge from "@mui/material/Badge";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ServiceRequestLogIcon from "@mui/icons-material/Article";
import SubjectIcon from "@mui/icons-material/Subject";
import { styled } from "@mui/system";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useNavigate, useParams } from "react-router-dom";

import CrudService from "services/cruds-service";
import LinearProgress from "@material-ui/core/LinearProgress";
import ParamValuesChart from "./ParamChart";
import DataTable from "examples/Tables/DataTable";
import ServiceRequestDataTable from "examples/Tables/ServiceRequestDataTable";
import { withStyles } from "@material-ui/core";
import moment from "moment";
import CollapsibleTable from "examples/Tables/ExpandTable";
import AlertRulesForm from "./alertrule/new";
import ServiceRequestsForm from "./service-request/new";
import ServiceRequestStatusLogForm from "./service-request/log";
import CalibrateDeviceForm from "./calibrate-device";
import DeviceDisconnectForm from "./disconnect-device";
import MultiSeviceRequestStatusEditForm from "./service-request/multiStatusEdit";
import MDAlert from "components/MDAlert";
import Skeleton from '@mui/material/Skeleton';
import GoogleMapComponent from "examples/Maps";
import { useMediaQuery } from 'react-responsive'

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    textWrap: "unwrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
    height: "auto",
    padding: "6px",
  },
}));

const StackSkeleton = () => {
  return <Stack spacing={0.5}>
    <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
    <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
    <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
    <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
  </Stack>
}

const ViewDevice = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const serviceRequestSelectedTab = localStorage.getItem("serviceRequestTab");

  const [name, setName] = useState({
    text: "",
    error: false,
    textError: "",
  });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const [paramValues, setParamValues] = useState([]);
  const [percent, setPercent] = useState(0);
  const [paramValue, setParamValue] = useState();
  const [device, setDevice] = useState();
  const [percentThreshold, setPercentThreshold] = useState();
  const [tableData, setTableData] = useState([]);
  const [serviceStatusUpdate, setServiceStatusUpdate] = useState("");
  const [serviceRequestId, setServiceRequestId] = useState("");
  const [deviceId, setDeviceId] = useState(id);
  const [logServiceRequestId, setLogServiceRequestId] = useState("");
  const [deviceStatusName, setDeviceStatusName] = useState("");
  const [deviceStatusId, setDeviceStatusId] = useState("");
  const [calibrateDeviceModal, setCalibrateDeviceModal] = useState(false);
  const [deviceDisconnectModal, setDeviceDisconnectModal] = useState(false);
  const [expandedIndexArray, setExpandedIndexArray] = useState([
    true,
    true,
    true,
    ...Array(6).fill(false),
  ]);
  const [isNotified, setNotified] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    parseInt(serviceRequestSelectedTab)
  );

  const [openViewModal, setOpenViewModal] = useState(false);
  const [serviceRequestLogModal, setServiceRequestLogModal] = useState(false);
  const [handleMode, setHandleMode] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [alert, setAlert] = useState();
  const [alertTableData, setAlertTableData] = useState([]);

  const [alertRule, setAlertRule] = useState([]);
  const [alertRules, setAlertRules] = useState([]);
  const [serviceRequests, setServiceRequests] = useState([]);
  const [serviceRequest, setServiceRequest] = useState([]);
  const [alertRuleTableData, setAlertRuleTableData] = useState([]);
  const [serviceRequestTableData, setServiceRequestTableData] = useState([]);

  /////////// service request ////////////
  const [openServiceModal, setServiceModal] = useState(false);
  const [serviceRequestEditFlag, setServiceRequestEditFlag] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [checkRowSelectedRowsFlag, setCheckSelectedRowsFlag] = useState(false);
  const [selectedRowsIdArray, setSelectedRowsIdArray] = useState([]);
  const [multiStatusEditModal, setMultiStatusEditModal] = useState(false);
  const [dataTableUpdate, setDataTableUpdate] = useState("");
  const [selectedRequestType, setSelectedRequestType] = useState("");
  const [serviceRequestDeleteFlag, setServiceRequestDeleteFlag] =
    useState(false);

  const [notification, setNotification] = useState({
    value: false,
    text: "",
  });

  const [pillbarColor, setPillbarColor] = useState("rgb(38, 40, 51);");
  const [providersState, setProvidersState] = useState([]);
  const [activeAlerts, setActiveAlerts] = useState([]);
  const [loaderT, setLoaderT] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [phoneEnvModal, setPhoneEnvModal] = useState(false)
  const [phoneEnv, setPhoneEnv] = useState({ is_production: false, is_sandbox: false, is_development: true })

  useEffect(() => {
    if (!id) return;
    setPillbarColor("rgb(38, 40, 51);");
    (async () => {
      try {
        setCheckSelectedRowsFlag(false);
        setSelectedRequestType("");
        setSelectedRows({});
        setServiceRequestEditFlag(false);
        setServiceRequestLogModal(false);
        setServiceRequestDeleteFlag(false);

        const res = await CrudService.getDeviceView(id);

        setDevice(res?.data);
        setProvidersState(
          res?.data?.entityAddressItemProviders.map((provider) => ({
            association_id: provider?.association_id,
            on_off: provider?.on_off || false,
          }))
        );

        setName({ text: res.data.attributes?.name });
        setNotified(res.data.attributes?.is_notified);
        const selected_phone = res.data.attributes?.SelectedPhone
        setPhoneEnv({ 
          is_production: selected_phone?.is_production,
          is_sandbox: selected_phone?.is_sandbox,
          is_development: selected_phone?.is_development
         })
        let lastRecord;

        if (res?.data?.monitorParameterValues) {
          const extractedData = res.data.monitorParameterValues.map((item) => ({
            value: {...item.value?.device_data, rsrp: item.value?.rsrp ?? '-'},
            data: item.value,
            id: item.id,
            status_name: item?.StatusType?.status_name
              ? item?.StatusType?.status_name
              : "",
            created_at: new Date(item.created_at).toISOString(), // Include full date with time
          }));

          // Create an object to store the last data entry for each unique date
          const paramValues = {};
          extractedData.forEach((item) => {
            const date = item.created_at.split("T")[0]; // Extract date without time
            paramValues[date] = item; // Store the data entry for each date, overwriting for duplicated dates
          });

          // Convert paramValues object to an array to match the previous structure
          const paramValuesArray = Object.values(paramValues);

          // const extractedData = res.data.monitorParameterValues.map(item => ({
          //     value: item.value?.device_data,
          //     data: item.value,
          //     created_at: item.created_at
          // }));

          setParamValues(paramValuesArray);
          lastRecord = extractedData.slice(-1)[0];
          console.log(lastRecord, "==========last record 1 =======");
          setParamValue(lastRecord?.data);
          setDeviceStatusName(lastRecord?.status_name);
          setDeviceStatusId(lastRecord?.device_status_id);
          console.log();
          localStorage.setItem(
            "device_params",
            JSON.stringify(lastRecord?.data)
          );
          setPercent(lastRecord?.value?.percent_level || 0);
        }

        const alertRes = await CrudService.getDeviceAlerts(id);
        setAlerts(alertRes.data);

        const alertRulesRes = await CrudService.getAlertRules(id);
        setAlertRules(alertRulesRes.data);

        const deviceAlertsRes = await CrudService.getDeviceActiveAlerts(id);
        setActiveAlerts(deviceAlertsRes?.data?.alerts);

        const serviceRequestssRes = await CrudService.getServiceRequests(
          res?.data?.attributes?.EntityAddressItem.address_item_id
        );

        setServiceRequests(serviceRequestssRes.data);

        let deviceThresholds = res?.data?.deviceThresholds;
        for (let deviceThreshold of deviceThresholds) {
          if (deviceThreshold.threshold_type_id === 1) {
            let color = "rgb(38, 40, 51);";
            let comparison_operator_id = deviceThreshold.comparison_operator_id;

            switch (comparison_operator_id) {
              case 1:
                color =
                  parseInt(lastRecord?.data?.device_data?.percent_level) ==
                    parseInt(deviceThreshold.threshold_value)
                    ? "#d32f2f"
                    : "rgb(38, 40, 51);";
                break;
              case 2:
                color =
                  parseInt(lastRecord?.data?.device_data?.percent_level) !=
                    parseInt(deviceThreshold.threshold_value)
                    ? "#d32f2f"
                    : "rgb(38, 40, 51);";
                break;
              case 3:
                color =
                  parseInt(lastRecord?.data?.device_data?.percent_level) <
                    parseInt(deviceThreshold.threshold_value)
                    ? "#d32f2f"
                    : "rgb(38, 40, 51);";
                break;
              case 4:
                color =
                  parseInt(lastRecord?.data?.device_data?.percent_level) <=
                    parseInt(deviceThreshold.threshold_value)
                    ? "#d32f2f"
                    : "rgb(38, 40, 51);";
                break;
              case 5:
                color =
                  parseInt(lastRecord?.data?.device_data?.percent_level) >
                    parseInt(deviceThreshold.threshold_value)
                    ? "#d32f2f"
                    : "rgb(38, 40, 51);";
                break;
              case 6:
                color =
                  parseInt(lastRecord?.data?.device_data?.percent_level) >=
                    parseInt(deviceThreshold.threshold_value)
                    ? "#d32f2f"
                    : "rgb(38, 40, 51);";
                break;
              default:
                color = "rgb(38, 40, 51);";
                break;
            }
            setPillbarColor(color);
          }
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [id, dataTableUpdate, serviceStatusUpdate]);

  useEffect(() => {
    setTableData(getRows(paramValues));
    if (paramValues.length) {
      setLoaderT(false)
    }
  }, [paramValues]);

  useEffect(() => {
    setAlertTableData(getAlertRows(alerts));
  }, [alerts]);

  useEffect(() => {
    setAlertRuleTableData(getAlertRuleRows(alertRules));
  }, [alertRules]);

  useEffect(() => {
    setServiceRequestTableData(getServiceRequestRows(serviceRequests));
  }, [serviceRequests]);

  useEffect(() => {
    checkRowSelected();
  }, [selectedRows]);

  useEffect(() => {
    if (notification.value === true) {
      let timer = setTimeout(() => {
        setNotification({
          value: false,
          text: "",
        });
      }, 5000);
    }
  }, [notification]);

  const handleClickIPAddress = () => {
    const url = `http://${paramValue.local_ip}`;
    window.open(url, "_blank");
  };

  const handlePhoneSwitch = (value, attr) => {
    setPhoneEnv((e) => ({...e, [attr]: value}))
  }

  const clickSetPhoneOption = async () => {

    if (phoneEnv.is_production === true) {
      if (device?.entityAddressItemProviders.length === 0) {
        setNotification({
          value: true,
          text: 'You don`t have any provider, please add that',
          bgColor: "info",
        });
        return
      }
    }

    if (phoneEnv.is_sandbox === true) {
      if (device.contacts.length === 0) {
        setNotification({
          value: true,
          text: 'You don`t have any contacts, please add that',
          bgColor: "info",
        });
        return
      }
    }
    const res = await CrudService.setPhoneOption({ device_id: device.id, option: phoneEnv });
    if (res.success) {
      device.attributes.SelectedPhone = {...device.attributes.SelectedPhone, ...phoneEnv}
    }

    setNotification({
      value: true,
      text: res.msg,
      bgColor: "info",
    });
    setPhoneEnvModal(false)
  }

  const clickProductionPhone = async () => {
    const res = await CrudService.setProductionPhone();
    setNotification({
      value: true,
      text: res,
      bgColor: "info",
    });
  };

  const clickTestPhone = async () => {
    const res = await CrudService.setTestPhone();
    setNotification({
      value: true,
      text: res,
      bgColor: "info",
    });
  };

  const clickCancelSelection = (e) => {
    setSelectedRows([]);
  };
  const checkRowSelected = () => {
    let result = false;
    let _selectedRowIdArray = [];

    for (const key in selectedRows) {
      if (selectedRows?.[key] == true) {
        result = true;
        _selectedRowIdArray.push(key);
      }
    }
    setSelectedRowsIdArray(_selectedRowIdArray);
    setCheckSelectedRowsFlag(result);
  };

  const showToast = (result) => {
    console.log("show toast");
    setNotification({
      value: true,
      text: result.message,
      bgColor: result.status == "201" ? "info" : "error",
    });
  };

  const getKeyWithSpecialValue = (obj, specialValue) => {
    const entry = Object.entries(obj).find(
      ([key, value]) => value === specialValue
    );
    return entry ? entry[0] : undefined;
  };

  const handleCloseMultiStatusEditModal = () => {
    setMultiStatusEditModal(false);
  };
  const getRows = (info) => {
    // Sort info array by id in descending order
    info.sort((a, b) => b.id - a.id);

    let updatedInfo = info.map((row, index) => {
      const formattedDate = moment(row?.created_at).format(
        "MMMM DD, YYYY h:mm:ss A"
      );
      let previousIndex;
      if (index == 0) {
        previousIndex = 0;
      } else {
        previousIndex = index - 1;
      }
      const statusText =
        row.attributes?.StatusOption?.StatusType?.status_name || "";
      return {
        id: row?.id,
        battery_status: row?.value?.battery_status,
        battery_voltage: row?.value?.battery_voltage,
        rsrp: row?.value?.rsrp !== '-' ? Number(row?.value?.rsrp / 10).toFixed(2) : row?.value?.rsrp,
        inch_level: row.value?.inch_level,
        percent_level: row.value?.percent_level,
        percent_level_drop:
          info[previousIndex].value?.percent_level - row.value?.percent_level,
        percent_level_increase:
          row.value?.percent_level - info[previousIndex].value?.percent_level,
        previous_percent_level: info[previousIndex].value?.percent_level,
        volume_level: row.value?.volume_level,
        date: row?.created_at,
        status_text:
          row.data?.status === "Working" ? "Working" : "Not Connected",
        status:
          row.data?.status === "Working" ? (
            <Badge
              variant="gradient"
              badgeContent="Working"
              color="success"
              style={{ marginLeft: "10px" }}
            ></Badge>
          ) : (
            <Badge
              variant="gradient"
              badgeContent="Not Connected"
              color="error"
              style={{ marginLeft: "-90px", width: "120px" }}
            ></Badge>
          ),
      };
    });

    return updatedInfo;
  };

  const dataTableData = {
    columns: [
      { Header: "ID", accessor: "id", show: true },
      { Header: "Status Badge", accessor: "status", show: paramValue?.isExternal ? false : true },
      {
        Header: "Battery Status",
        accessor: "battery_status",
        width: "20%",
        show: true,
      },
      {
        Header: "Battery Voltage",
        accessor: "battery_voltage",
        width: "20%",
        show: true,
      },
      {
        Header: "RSRP",
        accessor: "rsrp",
        width: "5%",
        show: true,
      },
      {
        Header: "Inch Level",
        accessor: "inch_level",
        width: "15%",
        show: true,
      },
      {
        Header: "Percent Level",
        accessor: "percent_level",
        width: "15%",
        show: true,
      },
      {
        Header: "Percent Level Drop",
        accessor: "percent_level_drop",
        width: "15%",
      },
      {
        Header: "Percent Level Increase",
        accessor: "percent_level_increase",
        width: "15%",
        show: true,
      },
      {
        Header: "Previous Percent Level",
        accessor: "previous_percent_level",
        width: "15%",
        show: true,
      },
      {
        Header: "Volume Level",
        accessor: "volume_level",
        width: "15%",
        show: true,
      },
      {
        Header: "Date",
        accessor: "date",
        show: true,
        isSortedDesc: true,
        isSorted: true,
        Cell: (info) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                maxWidth: "300px",
                wordWrap: "break-word",
                wordBreak: "break-word",
              }}
            >
              {moment(info.cell.row.original.date).format(
                "MMMM DD, YYYY h:mm:ss A"
              )}
            </div>
          );
        },
      },
    ],
    rows: tableData, // Assuming tableData is your original data array
  };
  const clickAlertRuleEditHandler = async (ruleId) => {
    let res = await CrudService.getAlertRule(ruleId);
    setAlertRule(res);
    setOpenViewModal(true);
  };

  const clickAlertRuleDeleteHandler = async (e, ruleId) => {
    try {
      if (!confirm("Are you sure you want to delete this alert rule?")) {
        e.nativeEvent.stopImmediatePropagation();
      } else {
        await CrudService.deleteAlertRule(ruleId);
        // the delete does not send a response
        // so I need to get again the categories to set it and this way the table gets updated -> it goes to the useEffect with data dependecy
        const response = await CrudService.getAlertRules(id);
        setAlertRules(response.data);
        setNotification({
          value: true,
          text: "The alert rule has been successfully deleted",
        });
      }
    } catch (err) {
      // it sends error is the item is associated with an item
      console.error(err);
      if (err.hasOwnProperty("errors")) {
        setNotification({
          value: true,
          text: err.errors[0].detail,
        });
      }
      return null;
    }
  };

  const clickServiceRequestEditHandler = async (e, service_request_id) => {
    e.stopPropagation();
    setServiceRequestEditFlag(true);
    let res = await CrudService.getServiceRequest(service_request_id);

    setServiceRequest(res.data.attributes);
    setServiceModal(true);
  };

  const clickServiceRequestLogHandler = async (e, service_request_id) => {
    e.stopPropagation();
    setServiceRequestLogModal(true);
    setLogServiceRequestId(service_request_id);
    // let res = await CrudService.getServiceRequest(service_request_id);

    // setServiceRequest(res.data.attributes);
  };

  const clickServiceRequestViewHandler = (e, service_request_id) => {
    e.stopPropagation();
    navigate(`/service-request-detail/${service_request_id}`);
  };
  const clickServiceRequestDeleteHandler = async (e, service_request_id) => {
    e.stopPropagation();
    setServiceRequestDeleteFlag(true);
    try {
      if (!confirm("Are you sure you want to delete this service request?")) {
        e.nativeEvent.stopImmediatePropagation();
      } else {
        await CrudService.deleteServiceRequest(service_request_id);
        // the delete does not send a response
        // so I need to get again the categories to set it and this way the table gets updated -> it goes to the useEffect with data dependecy
        const response = await CrudService.getServiceRequests(
          device?.attributes?.EntityAddressItem.address_item_id
        );
        setServiceRequests(response.data);
        setNotification({
          value: true,
          text: "The service request has been successfully deleted",
        });
      }
    } catch (err) {
      // it sends error is the item is associated with an item
      console.error(err);
      if (err.hasOwnProperty("errors")) {
        setNotification({
          value: true,
          text: err.errors[0].detail,
        });
      }
      return null;
    }
  };

  const getAlertRuleRows = (info) => {
    console.log(info, "==========alert rule rows=====");
    let updatedInfo = info.map((row) => {
      let frequency = "";
      if (row.attributes?.frequency && row.attributes?.frequency != null) {
        frequency =
          row.attributes?.frequency +
          " min" +
          (row.attributes?.frequency > 1 ? "(s)" : "");
      }

      let rule_type =
        row.attributes?.module_id === 12
          ? "Alert"
          : row.attributes?.module_id === 13
            ? "Service Request"
            : "";

      const event_type_id = row.attributes?.event_type_id;
      let message_template_name = "";
      if (event_type_id == 14) {
        message_template_name = row.attributes?.SendgridTemplate?.name;
      }
      if (event_type_id == 15) {
        message_template_name = row.attributes?.MessageTemplate?.name;
      }
      return {
        id: row?.id,
        rule_type: rule_type,
        alert_type: row.attributes?.AlertType?.type_name,
        alert_type_category: row.attributes?.AlertType?.category,
        alert_sub_type: row.attributes?.AlertSubType?.sub_type_name,
        event_type: row.attributes?.EventType?.name,
        message_template_name: message_template_name,
        address: row.attributes?.ContactAddress?.contact_address,
        send_to_type: row.attributes?.SendToType?.send_to_type_name,
        frequency: frequency,
        status_text:
          row.attributes?.StatusOption?.StatusType?.status_name || "",
        status: (
          <Badge
            color={
              row.attributes?.StatusOption?.BadgeDetail?.color || "primary"
            }
            variant={row.attributes?.StatusOption?.BadgeDetail?.variant}
            badgeContent={row.attributes?.StatusOption?.StatusType?.status_name}
            style={{ marginLeft: "30px" }}
          ></Badge>
        ),
      };
    });

    return updatedInfo;
  };

  const alertRuledataTableData = {
    columns: [
      { Header: "ID", accessor: "id", show: true },
      { Header: "Status Badge", accessor: "status", show: true },
      { Header: "Status ", accessor: "status_text", show: false },

      { Header: "Rule Type", accessor: "rule_type", show: true },
      { Header: "Alert Type", accessor: "alert_type", show: true },
      {
        Header: "Alert Type Category",
        accessor: "alert_type_category",
        show: true,
      },
      { Header: "Alert Sub Type", accessor: "alert_sub_type", show: true },
      { Header: "Event Type", accessor: "event_type", show: true },
      {
        Header: "Message Template Name",
        accessor: "message_template_name",
        show: true,
      },
      { Header: "Address", accessor: "address", show: true },
      { Header: "Send Type", accessor: "send_to_type", show: true },
      { Header: "Frequency", accessor: "frequency", show: true },
      {
        Header: "actions",
        disableSortBy: true,
        accessor: "",
        show: true,
        Cell: (info) => {
          return (
            <MDBox display="flex" alignItems="center">
              <Tooltip title="Edit Alert Rule">
                <IconButton
                  onClick={() =>
                    clickAlertRuleEditHandler(info.cell.row.original.id)
                  }
                >
                  <MDTypography>
                    <EditIcon />
                  </MDTypography>
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Alert Rule">
                <IconButton
                  onClick={(e) =>
                    clickAlertRuleDeleteHandler(e, info.cell.row.original.id)
                  }
                >
                  <MDTypography>
                    <DeleteIcon />
                  </MDTypography>
                </IconButton>
              </Tooltip>
            </MDBox>
          );
        },
      },
    ],
    rows: alertRuleTableData, // Assuming tableData is your original data array
  };

  const getServiceRequestRows = (info) => {
    let updatedInfo = info.map((row) => {
      return {
        id: row.attributes?.service_request_id,
        user: row.attributes?.user?.name,
        entityAddressItem: row.attributes?.EntityAddressItem?.name,
        provider: row.attributes?.Provider?.provider_name,
        serviceItem: row.attributes?.ServiceItem?.item_name,
        request_date: row.attributes?.request_date,
        status: row.attributes?.StatusOption,
        status_text:
          row.attributes?.StatusOption?.StatusType?.status_name || "",
      };
    });

    return updatedInfo;
  };

  const serviceRequestdataTableData = {
    columns: [
      { Header: "ID", accessor: "id", width: "12rem", show: true },
      {
        Header: "Status Badge",
        accessor: "",
        width: "12rem",
        show: true,
        Cell: (info) => {
          return (
            <StyledBadge
              color={
                info.cell.row.original?.status?.BadgeDetail?.color || "primary"
              }
              variant={
                info.cell.row.original?.status?.BadgeDetail?.variant ||
                "contained"
              }
              badgeContent={
                info.cell.row.original.status?.StatusType?.status_name
              }
              style={{ marginLeft: "30px" }}
            ></StyledBadge>
          );
        },
      },
      { Header: "Status", accessor: "status_text", width: "12rem", show: true },

      {
        Header: "Entity Address Item",
        accessor: "entityAddressItem",
        width: "12rem",
        show: true,
      },
      { Header: "Provider", accessor: "provider", width: "12rem", show: true },
      {
        Header: "Service Item",
        accessor: "serviceItem",
        width: "12rem",
        show: true,
      },
      {
        Header: "Requested Date",
        accessor: "request_date",
        width: "12rem",
        show: true,
      },
      { Header: "User", accessor: "user", width: "12rem", show: true },
      {
        Header: "actions",
        disableSortBy: true,
        accessor: "",
        width: "12rem",
        show: true,
        Cell: (info) => {
          return (
            <MDBox display="flex" alignItems="center">
              <Tooltip title="View Service Request">
                <IconButton
                  onClick={(e) =>
                    clickServiceRequestViewHandler(e, info.cell.row.original.id)
                  }
                >
                  <MDTypography>
                    <SubjectIcon />
                  </MDTypography>
                </IconButton>
              </Tooltip>
              <Tooltip title="Show Status Change Log">
                <IconButton
                  onClick={(e) =>
                    clickServiceRequestLogHandler(e, info.cell.row.original.id)
                  }
                >
                  <MDTypography>
                    <ServiceRequestLogIcon />
                  </MDTypography>
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit Service Request">
                <IconButton
                  onClick={(e) =>
                    clickServiceRequestEditHandler(e, info.cell.row.original.id)
                  }
                >
                  <MDTypography>
                    <EditIcon />
                  </MDTypography>
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Service Request">
                <IconButton
                  onClick={(e) =>
                    clickServiceRequestDeleteHandler(
                      e,
                      info.cell.row.original.id
                    )
                  }
                >
                  <MDTypography>
                    <DeleteIcon />
                  </MDTypography>
                </IconButton>
              </Tooltip>
            </MDBox>
          );
        },
      },
    ],
    rows: serviceRequestTableData, // Assuming tableData is your original data array
  };

  const getAlertRows = (info) => {
    let updatedInfo = info.map((row) => {
      const createdAt = row.attributes.created_at
        ? moment(row.attributes.created_at).format("MMMM DD, YYYY h:mm:ss A")
        : "";
      const updatedAt = row.attributes.updated_at
        ? moment(row.attributes.updated_at).format("MMMM DD, YYYY h:mm:ss A")
        : "";
      const subType = row.attributes.is_parent
        ? "Alert Created"
        : row.attributes.is_healed
          ? "Alert Healed"
          : "Alert Resent";

      return {
        id: row.id,
        device_id: row.attributes?.device_id,
        alert_type_id: row.attributes?.alert_type_id,
        alert_type: row.attributes?.AlertType?.type_name,
        alert_type_category: row.attributes?.AlertType?.category,
        device: row.attributes.Device?.name,
        sub_type: subType,
        is_parent: row.attributes?.is_parent,
        created_at: createdAt,
        updated_at: updatedAt,
        status_text:
          row.attributes?.StatusOption?.StatusType?.status_name || "",
        status: (
          <Badge
            color={
              row.attributes?.StatusOption?.BadgeDetail?.color || "primary"
            }
            variant={row.attributes?.StatusOption?.BadgeDetail?.variant}
            badgeContent={row.attributes?.StatusOption?.StatusType?.status_name}
            style={{ marginLeft: "30px" }}
          ></Badge>
        ),
      };
    });
    return updatedInfo;
  };

  const clickStatusEdit = (e) => {
    setMultiStatusEditModal(true);
  };

  const alertColumns = [
    { Header: "ID", accessor: "id", width: "5%", show: true },
    { Header: "Status Badge", accessor: "status", width: "15%", show: true },
    { Header: "Status", accessor: "status_text", show: false },
    { Header: "Alert Type", accessor: "alert_type", width: "15%", show: true },
    {
      Header: "Alert Category",
      accessor: "alert_type_category",
      width: "15%",
      show: true,
    },
    { Header: "Sub Type", accessor: "sub_type", width: "15%", show: true },
    { Header: "Device", accessor: "device", width: "15%", show: true },
    { Header: "Created At", accessor: "created_at", width: "15%", show: true },
    { Header: "Updated At", accessor: "updated_at", width: "15%", show: true },
    {
      Header: "actions",
      disableSortBy: true,
      accessor: "actions",
      show: true,
    },
  ];

  const clickViewHandler = async (id, value) => {
    let data = await CrudService.getAlert(id);
    setAlert(data);
    setHandleMode(true);
    setOpenViewModal(true);
  };

  const clickServiceReqeustRowHandler = (service_request_id) => {
    setServiceRequestId(service_request_id);
  };

  const StyledLinearProgress = withStyles({
    root: {
      height: 20, // Increase height of the progress bar here
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 10px", // Add padding for better visibility of percentage
      position: "relative", // Add position relative for absolute positioning of percentage
      borderRadius: "15px",
    },
    bar: {
      borderRadius: "5px",
      backgroundColor: pillbarColor,
    },
  })(LinearProgress);

  const handleExpand = (index) => {
    setExpandedIndexArray((prevArray) =>
      prevArray.map((item, idx) => (idx === index ? !item : item))
    );
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    localStorage.setItem("serviceRequestTab", newValue);
  };

  const renderTableCell = (data) => {
    // If the data is an object (JSON), render each key-value pair
    if (typeof data === "object" && data !== null) {
      return (
        <Table>
          <TableBody>
            {Object.entries(data).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell sx={{ padding: "1px!important;" }}>
                  {formatColumnName(key)}
                </TableCell>
                <TableCell sx={{ padding: "1px!important;" }}>
                  {renderTableCell(value)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }
    return data;
  };

  const formatColumnName = (name) => {
    return name
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
  };

  const DynamicDataTable = (Alert) => {
    // Parse JSON data and extract keys for table columns
    const columns = Object.keys(Alert);

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column}
                  sx={{
                    padding: "0px!important;",
                    color: "#7b809a !important;",
                  }}
                >
                  {formatColumnName(column)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column}>
                  {renderTableCell(Alert[column])}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const cardStyle = (index) => ({
    display: "flex",
    flexDirection: "column",
    ...(expandedIndexArray[index] && { minHeight: "100%" }),
  });

  const styles = {
    table: {
      padding: 0,
      borderSpacing: 0,
      borderCollapse: "collapse",
    },
    cell: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: isTabletOrMobile ? '0' : '15px',
      paddingRight: isTabletOrMobile ? '0' : '15px'

    },
    cell1: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: isTabletOrMobile ? '0' : '15px',
      paddingRight: isTabletOrMobile ? '0' : '15px',
      width: isTabletOrMobile ? '135px' : '165px'

    }

  };

  const handleViewClose = () => {
    setOpenViewModal(false);
  };
  const handleServiceRequestModalClose = () => {
    setServiceRequestLogModal(false);
  };

  const handleCalibrateDeviceModalClose = () => {
    setCalibrateDeviceModal(false);
  };
  const handleDeviceDisconnectModalClose = () => {
    setDeviceDisconnectModal(false);
  };

  const handleServiceModalClose = () => {
    setServiceModal(false);
  };

  // This function will handle the removal of the alert when clicked
  const handleClose = async (alertId, alertTypeId, deviceId) => {
    // Filter out the alert with the matching id
    const updatedAlerts = activeAlerts.filter(
      (alert) => alert.alert_id !== alertId
    );
    setActiveAlerts(updatedAlerts);
    const payload = {
      deviceId: deviceId,
      alertTypeId: alertTypeId,
    };
    await CrudService.updateNotificationStatus(payload);
  };

  const handleSelectedRows = (request_id, flag) => {
    if (
      serviceRequestEditFlag != true ||
      serviceRequestLogModal != true ||
      serviceRequestDeleteFlag != true
    ) {
      const firstSelectedServiceRequestId = getKeyWithSpecialValue(
        selectedRows,
        true
      );

      let serviceRequestType = "";
      if (firstSelectedServiceRequestId != undefined) {
        const selectedFirstRequest = serviceRequests.filter((request) => {
          return (
            request?.attributes?.service_request_id ==
            firstSelectedServiceRequestId
          );
        });
        if (selectedFirstRequest) {
          const selectedRequestTypeTemp =
            selectedFirstRequest[0]?.attributes.status_type_id;
          serviceRequestType = selectedRequestTypeTemp;

          setSelectedRequestType(selectedRequestTypeTemp);
        }
      } else {
        const firstRequest = serviceRequests.filter((request) => {
          return request?.attributes?.service_request_id == request_id;
        });

        const firstRequestType = firstRequest[0]?.attributes?.status_type_id;
        serviceRequestType = firstRequestType;
      }

      const selectedServiceRequest = serviceRequests.filter((request) => {
        return request?.attributes?.service_request_id == request_id;
      });

      if (selectedServiceRequest) {
        if (
          selectedServiceRequest[0]?.attributes?.status_type_id ==
          serviceRequestType
        ) {
          setSelectedRequestType(serviceRequestType);
          setSelectedRows({
            ...selectedRows,
            [request_id]: flag,
          });
        }
      }
    }
  };

  const handleProviderSwitchChange = async (association_id, newOnOffValue) => {
    setProvidersState((prevState) =>
      prevState.map((provider) =>
        provider.association_id === association_id
          ? { ...provider, on_off: newOnOffValue }
          : provider
      )
    );

    try {
      await CrudService.setProviderActive({
        association_id,
        on_off: newOnOffValue,
      });
      const serviceRequestssRes = await CrudService.getServiceRequests(
        device?.attributes?.EntityAddressItem.address_item_id
      );
      setServiceRequests(serviceRequestsRes.data);
    } catch (error) {
      console.error("Error updating provider:", error);
    }
  };

  const checkAllNotification = (alerts) => {
    let isAllNotification = false;
    alerts.forEach((alert) => {
      if (alert?.AlertType?.category != "notice") {
        isAllNotification = true;
        return;
      }
    });
    return isAllNotification;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {notification.value && (
        <MDAlert
          color={notification.bgColor}
          my="20px"
          sx={{
            position: "sticky",
            top: { md: "100px", xs: "50px" },
            zIndex: "10000",
          }}
        >
          <MDTypography variant="body2" color="white">
            {notification.text || ""}
          </MDTypography>
        </MDAlert>
      )}

      <MDBox mt={0} mb={9}>
        <Grid justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={0} mb={0} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Device {device?.attributes?.name}{" "}
                  {activeAlerts.length != 0 &&
                    checkAllNotification(activeAlerts) ? (
                    <i
                      className="fas fa-triangle-exclamation"
                      style={{ fontSize: "24px", color: "#d32f2f" }}
                    ></i>
                  ) : (
                    ""
                  )}
                </MDTypography>
                {activeAlerts.map((alert) => (
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      padding: "5px",
                      marginBottom: "10px",
                      border: `1px solid ${
                        // alert?.AlertType?.alert_type_id === 11 ||
                        // alert?.AlertType?.alert_type_id === 12
                        alert?.AlertType?.category == "notice"
                          ? "green"
                          : "#d32f2f"
                        }`,
                      alignItems: "center",
                      backgroundColor:
                        alert?.AlertType?.category == "notice"
                          ? "#d1ffd6"
                          : "#ffcccb",
                    }}
                  >
                    {/* Close button */}
                    {alert?.AlertType?.category == "notice" && (
                      <button
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          background: "transparent",
                          border: "none",
                          fontSize: "16px",
                          cursor: "pointer",
                          color: "black",
                        }}
                        onClick={() =>
                          handleClose(
                            alert?.alert_id,
                            alert?.alert_type_id,
                            alert?.device_id
                          )
                        } // Replace with your close function
                      >
                        &times;
                      </button>
                    )}
                    <MDTypography
                      variant="h6"
                      style={{
                        fontSize: "12px",
                        color:
                          alert?.AlertType?.category == "notice"
                            ? "green"
                            : "#d32f2f",
                        marginLeft: "10px",
                      }}
                    >
                      <i
                        className={`fas fa-${alert?.AlertType?.Icon?.icon_name}`}
                        style={{
                          marginRight: "5px",
                          fontSize: "16px",
                          color:
                            alert?.AlertType?.category == "notice"
                              ? "green"
                              : "#d32f2f",
                        }}
                      ></i>{" "}
                      {alert?.AlertType?.type_name} -{" "}
                      {alert?.AlertType?.description} &middot;{" "}
                      {moment(alert?.created_at).format(
                        "MMMM Do YYYY, h:mm:ss A"
                      )}
                    </MDTypography>
                  </div>
                ))}
              </MDBox>
            </MDBox>
            <Card>
              <MDBox>
                <MDBox display="flex" flexDirection="column" px={3} my={2}>
                  <MDBox>
                    <MDTypography>Tank Level</MDTypography>
                    <MDBox style={{ position: "relative" }}>
                      <StyledLinearProgress
                        variant="determinate"
                        value={percent}
                      />
                      <MDBox
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color={percent > 55 ? "white" : "black"}
                      >
                        <MDTypography variant="body2" color="textSecondary">
                          {`${percent}%`}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <ParamValuesChart paramValues={paramValues} />
                  <MDBox mb={3}>
                    <Grid container spacing={3}>
                      {[
                        {
                          title: "Device Information",
                          icon: "fas fa-info-circle",
                        },
                        { title: "Current Data", icon: "fas fa-chart-line" },
                        { title: "Monitor", icon: "fas fa-tachometer-alt" },
                      ].map((item, index) => (
                        <Grid item xs={12} sm={4} key={index}>
                          <Card style={cardStyle(index)}>
                            <CardHeader
                              title={
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <i
                                    className={item.icon}
                                    style={{ marginRight: "8px" }}
                                  ></i>
                                  {item.title}
                                </div>
                              }
                              style={{
                                backgroundColor: "#f0f0f0",
                                borderBottom: "1px solid #e0e0e0",
                                cursor: "pointer",
                              }}
                              onClick={() => handleExpand(index)}
                            />
                            {expandedIndexArray[index] && (
                              <CardContent style={isTabletOrMobile ? { padding: '16px' } : {}} >
                                {index === 0 && (
                                  <>
                                    {/* <Table
                                              key={index}
                                              style={{
                                                ...styles.table,
                                                marginTop: "5px",
                                               
                                              }}
                                            >
                                            <TableBody>
                                              <TableRow>
                                                <TableCell style={styles.cell1} className="text-sm-end text-lg-end">
                                                      <MDTypography fontSize={1}>
                                                    <span
                                                      style={{ fontWeight: "700" }}
                                                    >
                                                      Device:
                                                    </span>{" "}
                                                    
                                                  </MDTypography>
                                                  </TableCell>
                                                <TableCell style={styles.cell2}>
                                                   <MDTypography fontSize={1}>
                                                      {name?.text}
                                                    </MDTypography>
                                                  </TableCell>
                                                </TableRow>
                                              <TableRow>
                                                <TableCell style={styles.cell1}>
                                                      <MDTypography fontSize={1}>
                                                    <span
                                                      style={{ fontWeight: "700" }}
                                                    >
                                                         Item Type:
                                                    </span>{" "}
                                                    
                                                  </MDTypography>
                                                  </TableCell>
                                                <TableCell style={styles.cell2}>
                                                   <MDTypography fontSize={1}>
                                                      { device?.attributes
                                                  ?.EntityAddressItem?.Item
                                                  ?.ItemType?.type_name}
                                                    </MDTypography>
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell style={styles.cell1}>
                                                        <MDTypography fontSize={1}>
                                                      <span
                                                        style={{ fontWeight: "700" }}
                                                      >
                                                        Entity Address Id:
                                                      </span>{" "}
                                                      
                                                    </MDTypography>
                                                    </TableCell>
                                                  <TableCell style={styles.cell2}>
                                                    <MDTypography fontSize={1}>
                                                        {device?.attributes
                                                        ?.EntityAddressItem?.Address
                                                        ?.address_id}
                                                      </MDTypography>
                                                  </TableCell>
                                           </TableRow>

                                           					<TableRow>
                                                <TableCell style={styles.cell1}>
                                                      <MDTypography fontSize={1}>
                                                    <span
                                                      style={{ fontWeight: "700" }}
                                                    >
                                                         Item Name:
                                                    </span>{" "}
                                                    
                                                  </MDTypography>
                                                  </TableCell>
                                                <TableCell style={styles.cell2}>
                                                   <MDTypography fontSize={1}>
                                                      {
                                                      device?.attributes?.EntityAddressItem
                                                      ?.Item?.name
                                                      }
                                                    </MDTypography>
                                                  </TableCell>
                                           </TableRow>
                                              </TableBody>
                                              </Table>
                                    <table class="table table-borderless">
                                      <tbody>
                                    
                                        <tr>
                                          <th className="">
                                            <MDTypography fontSize={1}>
                                              <span
                                                style={{ fontWeight: "700" }}
                                              >
                                                 Address:
                                              </span>{" "}
                                              
                                            </MDTypography>
                                          </th>
                                          <th>
                                            <MDTypography fontSize={1}>
                                            {
                                        device?.attributes?.EntityAddressItem
                                          ?.Address?.street_address1
                                      }
                                      ,{" "}
                                      {
                                        device?.attributes?.EntityAddressItem
                                          ?.Address?.street_address2
                                      }{" "}
                                      {
                                        device?.attributes?.EntityAddressItem
                                          ?.Address?.city
                                      }
                                      ,{" "}
                                      {
                                        device?.attributes?.EntityAddressItem
                                          ?.Address?.state
                                      }
                                      ,{" "}
                                      {
                                        device?.attributes?.EntityAddressItem
                                          ?.Address?.postal_code
                                      }
                                      ,{" "}
                                      {
                                        device?.attributes?.EntityAddressItem
                                          ?.Address?.country
                                      }
                                            </MDTypography>
                                          </th>
                                        </tr>
                                      </tbody>
                                    </table>

                                   */}

                                    {
                                      device ? (
                                        <div className="table-responsive">
                                          <table className="table table-borderless">
                                            <tbody>

                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Device Name:
                                                  </span>{" "}

                                                </MDTypography

                                                ></th>
                                                <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                  {name?.text}
                                                </MDTypography>

                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Device Type:
                                                  </span>{" "}

                                                </MDTypography></th>
                                                <td style={styles.cell} >  <MDTypography fontSize={1}>
                                                  {
                                                    device?.attributes?.DeviceType
                                                      ?.type_name
                                                  }
                                                </MDTypography>

                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Brand:
                                                  </span>{" "}

                                                </MDTypography></th>
                                                <td style={styles.cell} >  <MDTypography fontSize={1}>
                                                  {
                                                    device?.attributes?.DeviceBrand
                                                      ?.brand_name
                                                  }
                                                </MDTypography>

                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Model:
                                                  </span>{" "}

                                                </MDTypography></th>
                                                <td style={styles.cell} >  <MDTypography fontSize={1}>
                                                  {
                                                    device?.attributes?.DeviceModel
                                                      ?.model_name
                                                  }
                                                </MDTypography>

                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1} >    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Item Type:
                                                  </span>{" "}

                                                </MDTypography

                                                ></th>
                                                <td style={styles.cell} >  <MDTypography fontSize={1}>
                                                  {device?.attributes
                                                    ?.EntityAddressItem?.Item
                                                    ?.ItemType?.type_name}
                                                </MDTypography>

                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}  >    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Entity Address Id:
                                                  </span>{" "}

                                                </MDTypography

                                                ></th>
                                                <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                  {device?.attributes
                                                    ?.EntityAddressItem?.Address
                                                    ?.address_id}
                                                </MDTypography>

                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Item Name:
                                                  </span>{" "}

                                                </MDTypography></th>
                                                <td style={styles.cell} >  <MDTypography fontSize={1}>
                                                  {
                                                    device?.attributes?.EntityAddressItem
                                                      ?.Item?.name
                                                  }
                                                </MDTypography>

                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Address:
                                                  </span>{" "}

                                                </MDTypography></th>
                                                <td style={styles.cell} >  <MDTypography fontSize={1}>
                                                  {
                                                    device?.attributes?.EntityAddressItem
                                                      ?.Address?.street_address1
                                                  }
                                                  ,{" "}
                                                  {
                                                    device?.attributes?.EntityAddressItem
                                                      ?.Address?.street_address2
                                                  }{" "}
                                                  {
                                                    device?.attributes?.EntityAddressItem
                                                      ?.Address?.city
                                                  }
                                                  ,{" "}
                                                  {
                                                    device?.attributes?.EntityAddressItem
                                                      ?.Address?.state
                                                  }
                                                  ,{" "}
                                                  {
                                                    device?.attributes?.EntityAddressItem
                                                      ?.Address?.postal_code
                                                  }
                                                  ,{" "}
                                                  {
                                                    device?.attributes?.EntityAddressItem
                                                      ?.Address?.country
                                                  }
                                                </MDTypography>

                                                </td>
                                              </tr>


                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Status:
                                                  </span>{" "}

                                                </MDTypography></th>
                                                <td style={styles.cell} >  <MDTypography fontSize={1}>
                                                  {
                                                    device?.attributes?.StatusOption
                                                      ?.StatusType?.status_name
                                                  }
                                                </MDTypography>

                                                </td>
                                              </tr>


                                            </tbody>
                                          </table>
                                        </div>
                                      ) : <StackSkeleton />
                                    }




                                  </>
                                )}
                                {index === 1 && (
                                  <>
                                    {
                                      !paramValue && <StackSkeleton />
                                    }

                                    <table className="table table-borderless">
                                      <tbody>
                                        {
                                          paramValue && (
                                            <>
                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Battery Status:
                                                  </span>{" "}

                                                </MDTypography

                                                ></th>
                                                <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                  {paramValue?.device_data
                                                    ?.battery_status === "Good" ? (
                                                    <Badge
                                                      variant="gradient"
                                                      badgeContent="Good"
                                                      color="success"
                                                      style={{
                                                        marginLeft: "30px",
                                                      }}
                                                    ></Badge>
                                                  ) : (
                                                    <Badge
                                                      variant="gradient"
                                                      badgeContent="Not Good"
                                                      color="error"
                                                      style={{
                                                        marginLeft: "-60px",
                                                        width: "100px",
                                                      }}
                                                    ></Badge>
                                                  )}
                                                </MDTypography>

                                                </td>
                                              </tr>

                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Battery Voltage:
                                                  </span>{" "}

                                                </MDTypography

                                                ></th>
                                                <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                  {paramValue?.device_data?.battery_voltage}
                                                </MDTypography>

                                                </td>
                                              </tr>
                                              {
                                                paramValue?.isExternal &&
                                                <tr>
                                                  <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                    <span
                                                      style={{ fontWeight: "700" }}
                                                    >
                                                      Measured Height:
                                                    </span>{" "}

                                                  </MDTypography

                                                  ></th>
                                                  <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                    {typeof paramValue?.device_data?.measured_height === 'number' ? `${paramValue?.device_data?.measured_height} Inches` : paramValue?.device_data?.measured_height}
                                                  </MDTypography>

                                                  </td>
                                                </tr>
                                              }
                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    {paramValue?.isExternal ? 'Product Level' : 'Fluid Level'}:
                                                  </span>{" "}

                                                </MDTypography

                                                ></th>
                                                <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                  {paramValue?.device_data?.inch_level}{" "}
                                                  Inches
                                                </MDTypography>

                                                </td>
                                              </tr>

                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Percent Level:
                                                  </span>{" "}

                                                </MDTypography

                                                ></th>
                                                <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                  {paramValue?.device_data?.percent_level}

                                                </MDTypography>

                                                </td>
                                              </tr>

                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Volume Level:
                                                  </span>{" "}

                                                </MDTypography

                                                ></th>
                                                <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                  {
                                                    paramValue?.device_data?.volume_level
                                                  }{" "} Gallons
                                                </MDTypography>

                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Temperature:
                                                  </span>{" "}

                                                </MDTypography

                                                ></th>
                                                <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                  {
                                                    paramValue?.device_data
                                                      ?.enclosure_temperature
                                                  }{" "}
                                                  {paramValue?.temperature_units}
                                                </MDTypography>

                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Latitude:
                                                  </span>{" "}

                                                </MDTypography

                                                ></th>
                                                {
                                                  device?.attributes?.EntityAddressItem?.Address?.latitude ?
                                                    <td style={{ ...styles.cell, cursor: 'pointer' }} onClick={() => setOpenModal(true)}>  <MDTypography fontSize={1}>
                                                      {
                                                        device?.attributes?.EntityAddressItem?.Address?.latitude
                                                      }
                                                    </MDTypography>

                                                    </td> : <></>
                                                }
                                              </tr>
                                              <tr>
                                                <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                  <span
                                                    style={{ fontWeight: "700" }}
                                                  >
                                                    Longitude:
                                                  </span>{" "}

                                                </MDTypography

                                                ></th>
                                                {
                                                  device?.attributes?.EntityAddressItem?.Address?.longitude ?
                                                    <td style={{ ...styles.cell, cursor: 'pointer' }} onClick={() => setOpenModal(true)}>  <MDTypography fontSize={1}>
                                                      {
                                                        device?.attributes?.EntityAddressItem?.Address?.longitude
                                                      }
                                                    </MDTypography>

                                                    </td> : <></>
                                                }
                                              </tr>
                                            </>
                                          )
                                        }



                                      </tbody>
                                    </table>

                                  </>
                                )}

                                {index === 2 && (
                                  <>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={isNotified}
                                          onChange={(e) => {
                                            setNotified(!isNotified);
                                            console.log(
                                              isNotified,
                                              id,
                                              "------------"
                                            );
                                            CrudService.setDeviceAlertEnable({
                                              deviceId: id,
                                              is_notified: !isNotified,
                                            });
                                          }}
                                        />
                                      }
                                      label="Alerts Enabled"
                                      mt={5}
                                      mb={5}
                                    />
                                    {
                                      !device && <StackSkeleton />
                                    }
                                    {
                                      device &&
                                      <MDBox>
                                        <table className="table table-borderless">
                                          <tbody>

                                            <tr>
                                              <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                <span
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  Name:{" "}
                                                </span>{" "}

                                              </MDTypography

                                              ></th>
                                              <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                {device?.monitor}
                                              </MDTypography>

                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                <span
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  Status:
                                                </span>{" "}

                                              </MDTypography

                                              ></th>
                                              <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                <Badge
                                                  color={
                                                    device?.monitor_status
                                                      ?.BadgeDetail?.color ||
                                                    "primary"
                                                  }
                                                  variant={
                                                    device?.monitor_status
                                                      ?.BadgeDetail?.variant
                                                  }
                                                  badgeContent={
                                                    device?.monitor_status?.StatusType
                                                      ?.status_name
                                                  }
                                                  style={{
                                                    marginLeft: "30px",
                                                  }}
                                                ></Badge>
                                              </MDTypography>

                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                <span
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  Frequency:
                                                </span>{" "}

                                              </MDTypography

                                              ></th>
                                              <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                {device?.monitor_frequency}{" "}
                                                {device?.monitor_time_unit}
                                              </MDTypography>

                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                <span
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  Last Updated:
                                                </span>{" "}

                                              </MDTypography

                                              ></th>
                                              <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                {moment(
                                                  device?.monitor_last_updated
                                                ).format("MMMM Do YYYY, h:mm:ss a")}
                                              </MDTypography>

                                              </td>
                                            </tr>



                                          </tbody>
                                        </table>


                                        {device?.deviceThresholds.map(
                                          (threshold, thresholdIndex) => (
                                            <Table
                                              key={thresholdIndex + 3}
                                              style={{
                                                ...styles.table,
                                                marginTop: "5px",
                                                boxShadow:
                                                  "0 2px 4px rgba(0, 0, 0, 0.1)",
                                              }}
                                            >
                                              <TableBody>
                                                <TableRow>
                                                  <TableCell
                                                    style={styles.cell}
                                                  >
                                                    <MDTypography fontSize={1}>
                                                      <span
                                                        style={{
                                                          fontWeight: "700",
                                                        }}
                                                      >
                                                        {" "}
                                                        Alert Type:
                                                      </span>
                                                    </MDTypography>
                                                  </TableCell>
                                                  <TableCell
                                                    style={styles.cell}
                                                  >
                                                    <MDTypography fontSize={1}>
                                                      {
                                                        threshold?.AlertType
                                                          ?.type_name
                                                      }
                                                    </MDTypography>
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell
                                                    style={styles.cell}
                                                  >
                                                    <MDTypography fontSize={1}>
                                                      <span
                                                        style={{
                                                          fontWeight: "700",
                                                        }}
                                                      >
                                                        {" "}
                                                        Parameter
                                                      </span>
                                                    </MDTypography>
                                                  </TableCell>
                                                  <TableCell
                                                    style={styles.cell}
                                                  >
                                                    <MDTypography fontSize={1}>
                                                      {
                                                        threshold
                                                          ?.DeviceParameter
                                                          ?.label
                                                      }
                                                    </MDTypography>
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell
                                                    style={styles.cell}
                                                  >
                                                    <MDTypography fontSize={1}>
                                                      <span
                                                        style={{
                                                          fontWeight: "700",
                                                        }}
                                                      >
                                                        {" "}
                                                        Comparison Operator:
                                                      </span>
                                                    </MDTypography>
                                                  </TableCell>
                                                  <TableCell
                                                    style={styles.cell}
                                                  >
                                                    <MDTypography fontSize={1}>
                                                      {
                                                        threshold
                                                          ?.ComparisonOperator
                                                          ?.label
                                                      }
                                                    </MDTypography>
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell
                                                    style={styles.cell}
                                                  >
                                                    <MDTypography fontSize={1}>
                                                      <span
                                                        style={{
                                                          fontWeight: "700",
                                                        }}
                                                      >
                                                        {" "}
                                                        Threshold Comparison
                                                        Operator:
                                                      </span>
                                                    </MDTypography>
                                                  </TableCell>
                                                  <TableCell
                                                    style={styles.cell}
                                                  >
                                                    <MDTypography fontSize={1}>
                                                      {
                                                        threshold?.threshold_value
                                                      }
                                                    </MDTypography>
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          )
                                        )}
                                      </MDBox>
                                    }
                                  </>
                                )}
                              </CardContent>
                            )}
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </MDBox>

                  <MDBox mb={3}>
                    <Grid container spacing={3}>
                      {[
                        { title: "Transmission", icon: "fas fa-signal" },
                        { title: "Providers", icon: "fas fa-truck" },
                        {
                          title: "Contact Detail",
                          icon: "fas fa-address-book",
                        },
                      ].map((item, index) => (
                        <Grid item xs={12} sm={4} key={index + 3}>
                          <Card style={cardStyle(index + 3)}>
                            <CardHeader
                              title={
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <i
                                    className={item.icon}
                                    style={{ marginRight: "8px" }}
                                  ></i>
                                  {item.title}
                                </div>
                              }
                              style={{
                                backgroundColor: "#f0f0f0",
                                borderBottom: "1px solid #e0e0e0",
                                cursor: "pointer",
                              }}
                              onClick={() => handleExpand(index + 3)}
                            />
                            {expandedIndexArray[index + 3] && (
                              <CardContent>
                                {index === 0 && (
                                  <>
                                    {
                                      paramValue ? (
                                        <table className="table table-borderless">
                                          <tbody>

                                            <tr>
                                              <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                <span
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  Status:
                                                </span>{" "}

                                              </MDTypography

                                              ></th>
                                              <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                {deviceStatusName === "Working" ? (
                                                  <Badge
                                                    variant="gradient"
                                                    badgeContent={deviceStatusName}
                                                    color="success"
                                                    style={{
                                                      marginLeft: "40px",
                                                    }}
                                                  ></Badge>
                                                ) : deviceStatusName != "" ? (
                                                  <Badge
                                                    variant="gradient"
                                                    badgeContent={deviceStatusName}
                                                    color="error"
                                                    style={{
                                                      marginLeft: "-60px",
                                                      width: "120px",
                                                    }}
                                                  ></Badge>
                                                ) : (
                                                  ""
                                                )}
                                              </MDTypography>

                                              </td>
                                            </tr>

                                            <tr>
                                              <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                <span
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  Signal Strength:
                                                </span>{" "}

                                              </MDTypography

                                              ></th>
                                              <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                {paramValue?.isExternal ? `${(paramValue?.tx_signal).toFixed(2)}dBm` : paramValue?.tx_signal}
                                              </MDTypography>

                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                <span
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  WiFi Signal:
                                                </span>{" "}

                                              </MDTypography

                                              ></th>
                                              <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                {paramValue?.wifi_signal}
                                              </MDTypography>

                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                <span
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  Reported:
                                                </span>{" "}

                                              </MDTypography

                                              ></th>
                                              <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                {paramValue?.isExternal ? moment(paramValue?.timestamp).format("MMM Do YYYY, h:mm A") : paramValue.tx_reported}
                                              </MDTypography>

                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                <span
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  Last Reported:
                                                </span>{" "}

                                              </MDTypography

                                              ></th>
                                              <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                {paramValue?.isExternal ? moment(paramValue?.timestamp).format("MMM Do YYYY, h:mm A") : paramValue.reported}
                                              </MDTypography>

                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                <span
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  Last Updated:
                                                </span>{" "}

                                              </MDTypography

                                              ></th>
                                              <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                {
                                                  paramValue?.isExternal ?
                                                    moment(paramValue?.timestamp).fromNow()
                                                    : moment(
                                                      paramValue?.reported,
                                                      "MMM Do, h:mm A"
                                                    ).fromNow()
                                                }
                                              </MDTypography>

                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row" className="text-sm-end text-start" style={styles.cell}>    <MDTypography fontSize={1}>
                                                <span
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  Last Updated on:
                                                </span>{" "}

                                              </MDTypography

                                              ></th>
                                              <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                {paramValue?.last_updated_on}
                                              </MDTypography>

                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                <span
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  IP Address:
                                                </span>{" "}

                                              </MDTypography

                                              ></th>
                                              <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                <a
                                                  // href={`http://${paramValue.local_ip}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  style={{ color: "blue" }}
                                                  onClick={handleClickIPAddress}
                                                >
                                                  {paramValue?.local_ip}
                                                </a>
                                              </MDTypography>

                                              </td>
                                            </tr>

                                          </tbody>
                                        </table>
                                      ) : <StackSkeleton />
                                    }

                                  </>
                                )}
                                {index === 1 && (
                                  <>
                                    <MDBox>
                                      <Grid
                                        container
                                        justifyContent="space-between"
                                      >
                                        <Grid item>
                                          <MDTypography variant="h6">
                                            Provider
                                          </MDTypography>
                                        </Grid>
                                        <Grid item>
                                          <MDTypography variant="h6" mr={3}>
                                            Share
                                          </MDTypography>
                                        </Grid>
                                      </Grid>
                                      {device?.entityAddressItemProviders.map(
                                        (provider) => (
                                          <MDBox
                                            key={provider?.association_id}
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              width: "100%",
                                            }}
                                          >
                                            <MDBox
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Avatar
                                                src={
                                                  provider?.Provider?.logo_url
                                                }
                                                alt={
                                                  provider?.Provider
                                                    ?.provider_name
                                                }
                                                sx={{
                                                  width: 60,
                                                  height: 60,
                                                }}
                                              />
                                              <MDTypography ml={2}>
                                                {
                                                  provider?.Provider
                                                    ?.provider_name
                                                }
                                              </MDTypography>
                                            </MDBox>
                                            <FormControlLabel
                                              control={
                                                <Switch
                                                  checked={
                                                    providersState.find(
                                                      (p) =>
                                                        p.association_id ===
                                                        provider.association_id
                                                    )?.on_off
                                                  }
                                                  onChange={async (e) => {
                                                    const newOnOffValue =
                                                      e.target.checked;
                                                    await handleProviderSwitchChange(
                                                      provider.association_id,
                                                      newOnOffValue
                                                    );
                                                  }}
                                                />
                                              }
                                              label=""
                                              sx={{
                                                mt: 5,
                                                mb: 5,
                                              }}
                                            />
                                          </MDBox>
                                        )
                                      )}
                                    </MDBox>
                                  </>
                                )}
                                {index === 2 &&
                                  device &&
                                  device.contacts &&
                                  device.contacts.map(
                                    (contact, contactindex) => (
                                      <MDBox key={contactindex + 3}>
                                        <table className="table table-borderless">
                                          <tbody>

                                            <tr>
                                              <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                <span
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  Contact Name:
                                                </span>{" "}

                                              </MDTypography

                                              ></th>
                                              <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                {contact?.Contact?.first_name}{" "}
                                                {contact?.Contact?.last_name}
                                              </MDTypography>

                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                <span
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  Status:
                                                </span>{" "}

                                              </MDTypography

                                              ></th>
                                              <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                {
                                                  contact?.Contact?.StatusOption
                                                    ?.StatusType?.status_name
                                                }
                                              </MDTypography>

                                              </td>
                                            </tr>





                                            {contact?.Contact?.ContactAddresses && (
                                              contact?.Contact?.ContactAddresses.map((address, subindex) => (

                                                <tr key={subindex + 3}>
                                                  <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                    <span
                                                      style={{ fontWeight: "700" }}
                                                    >
                                                      {
                                                        address
                                                          ?.ContactAddressType
                                                          ?.type_name
                                                      }:
                                                    </span>{" "}

                                                  </MDTypography

                                                  ></th>
                                                  <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                    {
                                                      address?.contact_address
                                                    }
                                                  </MDTypography>

                                                  </td>
                                                </tr>
                                              ))
                                              // <Table
                                              //   style={{
                                              //     ...styles.table,
                                              //     boxShadow:
                                              //       "0 2px 4px rgba(0, 0, 0, 0.1)",
                                              //   }}
                                              //   responsive
                                              // >
                                              //   <TableBody>
                                              //     {contact?.Contact?.ContactAddresses.map(
                                              //       (address, subindex) => (
                                              //         <TableRow key={subindex + 3}>
                                              //           <TableCell
                                              //             style={{
                                              //               padding: "0px",
                                              //             }}
                                              //           >
                                              //             <Grid
                                              //               container
                                              //               spacing={2}
                                              //             >
                                              //               <Grid item>
                                              //                 <MDTypography
                                              //                   fontSize={1}
                                              //                   style={{
                                              //                     fontWeight: "700",
                                              //                   }}
                                              //                 >
                                              //                   {
                                              //                     address
                                              //                       ?.ContactAddressType
                                              //                       ?.type_name
                                              //                   }
                                              //                 </MDTypography>
                                              //               </Grid>
                                              //               <Grid item>
                                              //                 <MDTypography
                                              //                   fontSize={1}
                                              //                 >
                                              //                   {
                                              //                     address?.contact_address
                                              //                   }
                                              //                 </MDTypography>
                                              //               </Grid>
                                              //             </Grid>
                                              //           </TableCell>
                                              //         </TableRow>
                                              //       )
                                              //     )}
                                              //   </TableBody>
                                              // </Table>
                                            )}

                                          </tbody>
                                        </table>
                                      </MDBox>
                                    )
                                  )}
                              </CardContent>
                            )}
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </MDBox>

                  <MDBox mb={3}>
                    <Grid container spacing={3}>
                      {Array.from({ length: 3 }).map((_, index) => (
                        <Grid item xs={12} sm={4} key={index + 6}>
                          <Card style={cardStyle(index + 6)}>
                            <CardHeader
                              title={
                                index === 0 ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      className="fas fa-cube"
                                      style={{ marginRight: "8px" }}
                                    ></i>
                                    <span style={{ fontWeight: "700" }}>
                                      {" "}
                                      Attributes
                                    </span>
                                  </div>
                                ) : index === 1 ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      className="fas fa-wrench"
                                      style={{ marginRight: "8px" }}
                                    ></i>
                                    <span style={{ fontWeight: "700" }}>
                                      Device Setup
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      className="fas fa-cogs"
                                      style={{ marginRight: "8px" }}
                                    ></i>
                                    <span style={{ fontWeight: "700" }}>
                                      {" "}
                                      Actions
                                    </span>
                                  </div>
                                )
                              }
                              style={{
                                backgroundColor: "#f0f0f0",
                                borderBottom: "1px solid #e0e0e0",
                                cursor: "pointer",
                              }}
                              onClick={() => handleExpand(index + 6)}
                            />
                            {expandedIndexArray[index + 6] && (
                              <CardContent
                                style={{
                                  flex: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {index === 0 && (

                                  <table className="table table-borderless">
                                    <tbody>


                                      {device?.itemAttributeValues.map(
                                        (attr, attrindex) => (
                                          <tr key={attrindex}>
                                            <th scope="row" className="text-sm-end text-start" style={styles.cell}>    <MDTypography fontSize={1}>
                                              <span
                                                style={{ fontWeight: "700" }}
                                              >
                                                {attr?.Attribute?.attribute_name}{" "} :
                                              </span>{" "}

                                            </MDTypography

                                            ></th>
                                            <td style={styles.cell}>  <MDTypography fontSize={1}>
                                              {attr?.value}
                                            </MDTypography>

                                            </td>
                                          </tr>

                                        )
                                      )}
                                    </tbody>
                                  </table>
                                )}
                                {index === 1 && (
                                  <MDBox>

                                    <table className="table table-borderless">
                                      <tbody>

                                        <tr>
                                          <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                            <span
                                              style={{ fontWeight: "700" }}
                                            >
                                              External ID:{" "}
                                            </span>{" "}

                                          </MDTypography

                                          ></th>
                                          <td style={styles.cell}>  <MDTypography fontSize={1}>
                                            {device?.external_device_id}
                                          </MDTypography>

                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                            <span
                                              style={{ fontWeight: "700" }}
                                            >
                                              Reference ID:{" "}
                                            </span>{" "}

                                          </MDTypography

                                          ></th>
                                          <td style={styles.cell}>  <MDTypography fontSize={1}>
                                            {paramValue?.id}
                                          </MDTypography>

                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                            <span
                                              style={{ fontWeight: "700" }}
                                            >
                                              Depth:{" "}
                                            </span>{" "}

                                          </MDTypography

                                          ></th>
                                          <td style={styles.cell}>  <MDTypography fontSize={1}>
                                            {paramValue?.device_setup?.depth}
                                          </MDTypography>

                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                            <span
                                              style={{ fontWeight: "700" }}
                                            >
                                              Shape:{" "}
                                            </span>{" "}

                                          </MDTypography

                                          ></th>
                                          <td style={styles.cell}>  <MDTypography fontSize={1}>
                                            {paramValue?.device_setup?.shape}
                                          </MDTypography>

                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                            <span
                                              style={{ fontWeight: "700" }}
                                            >
                                              Width:{" "}
                                            </span>{" "}

                                          </MDTypography

                                          ></th>
                                          <td style={styles.cell}>  <MDTypography fontSize={1}>
                                            {paramValue?.device_setup?.width}
                                          </MDTypography>

                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                            <span
                                              style={{ fontWeight: "700" }}
                                            >
                                              Length:{" "}
                                            </span>{" "}

                                          </MDTypography

                                          ></th>
                                          <td style={styles.cell}>  <MDTypography fontSize={1}>
                                            {paramValue?.device_setup?.length}
                                          </MDTypography>

                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                            <span
                                              style={{ fontWeight: "700" }}
                                            >
                                              Power X:{" "}
                                            </span>{" "}

                                          </MDTypography

                                          ></th>
                                          <td style={styles.cell}>  <MDTypography fontSize={1}>
                                            {paramValue?.device_setup?.power_x}
                                          </MDTypography>

                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                            <span
                                              style={{ fontWeight: "700" }}
                                            >
                                              Power Y:{" "}
                                            </span>{" "}

                                          </MDTypography

                                          ></th>
                                          <td style={styles.cell}>  <MDTypography fontSize={1}>
                                            {paramValue?.device_setup?.power_y}
                                          </MDTypography>

                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                            <span
                                              style={{ fontWeight: "700" }}
                                            >
                                              Power Z:{" "}
                                            </span>{" "}

                                          </MDTypography

                                          ></th>
                                          <td style={styles.cell}>  <MDTypography fontSize={1}>
                                            {paramValue?.device_setup?.power_z}
                                          </MDTypography>

                                          </td>
                                        </tr>

                                        {
                                          device.attributes?.DeviceIdentifierValues?.map((e, i) => (
                                            <tr key={i}>
                                              <th scope="row" className="text-sm-end text-start" style={styles.cell1}>    <MDTypography fontSize={1}>
                                                <span
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  {e.DeviceIdentifierType.identifier_type_name}:{" "}
                                                </span>{" "}

                                              </MDTypography

                                              ></th>
                                              <td style={styles.cell}>  <MDTypography fontSize={1}>
                                                {e.value}
                                              </MDTypography>

                                              </td>
                                            </tr>
                                          ))
                                        }

                                      </tbody>
                                    </table>

                                  </MDBox>
                                )}
                                {index === 2 && (
                                  <>
                                    <MDButton
                                      color="dark"
                                      onClick={() => {
                                        setServiceRequestEditFlag(false);
                                        setServiceModal(true);
                                        setServiceRequest(null);
                                      }}
                                    >
                                      Create Service Request
                                    </MDButton>
                                    {percent >= 20 && percent <= 80 && (
                                      <MDButton
                                        color="info"
                                        sx={{
                                          marginTop: "10px",
                                        }}
                                        onClick={() => {
                                          setCalibrateDeviceModal(true);
                                        }}
                                      >
                                        <i
                                          id="calibrate-icon"
                                          className="fas fa-cog"
                                          style={{ marginRight: "5px" }}
                                        ></i>
                                        Calibrate Device
                                      </MDButton>
                                    )}

                                    {deviceStatusName == "Working" && (
                                      <MDButton
                                        color="error"
                                        sx={{
                                          marginTop: "10px",
                                        }}
                                        onClick={() => {
                                          setDeviceDisconnectModal(true);
                                        }}
                                      >
                                        Disconnect Device
                                      </MDButton>
                                    )}

                                    {/* {(
                                                                        device?.alerts ? (
                                                                            device?.alerts.map(alert => (
                                                                                <MDBox style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                                                                    <MDTypography fontSize={1}>NO: {alert?.alert_id}</MDTypography>
                                                                                    <MDTypography fontSize={1}>Status: 
                                                                                        <Badge
                                                                                            color={alert?.StatusOption.BadgeDetail?.color || 'primary'}
                                                                                            variant={alert?.StatusOption.BadgeDetail?.variant}
                                                                                            badgeContent={alert?.StatusOption.StatusType?.status_name}
                                                                                            style={{marginLeft: '30px'}}
                                                                                        >
                                                                                        </Badge>
                                                                                    </MDTypography>
                                                                                    <MDTypography fontSize={1}>Alert Type: {alert?.AlertType?.type_name}</MDTypography>
                                                                                    <MDTypography fontSize={1}>Threshold: {alert?.threshold}</MDTypography>
                                                                                    <MDTypography fontSize={1}>Exceed Value: {alert?.exceed_value}</MDTypography>
                                                                                </MDBox>
                                                                            )
                                                                        )) : (
                                                                            <MDTypography>
                                                                                No Alert available
                                                                            </MDTypography>
                                                                        )
                                                                    )} */}
                                  </>
                                )}
                              </CardContent>
                            )}
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </MDBox>
                  <Modal
                    open={openViewModal}
                    onClose={handleViewClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                  >
                    <Card
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80%", // Set the desired width here
                        backgroundColor: "white",
                        p: 4,
                      }}
                    >
                      <AlertRulesForm
                        alertRule={alertRule}
                        setAlertRules={setAlertRules}
                        deviceId={id}
                        setOpenViewModal={setOpenViewModal}
                      />
                    </Card>
                  </Modal>
                  {/* multi status edit modal */}
                  <Modal
                    open={multiStatusEditModal}
                    onClose={handleCloseMultiStatusEditModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                  >
                    <Card
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "90%", sm: "40%" }, // Set the desired width here
                        backgroundColor: "white",
                        p: 2,
                      }}
                    >
                      <MultiSeviceRequestStatusEditForm
                        selectedRowsIdArray={selectedRowsIdArray}
                        setMultiStatusEditModal={setMultiStatusEditModal}
                        serviceRequestId={serviceRequestId}
                        setDataTableUpdate={setDataTableUpdate}
                        selectedRequestType={selectedRequestType}
                        showToast={showToast}
                        totalServiceRequestManagement={false}
                      />
                    </Card>
                  </Modal>
                  <Modal
                    open={serviceRequestLogModal}
                    onClose={handleServiceRequestModalClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                  >
                    <Card
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",

                        width: "80%", // Set the desired width here
                        backgroundColor: "white",
                        p: 4,
                      }}
                    >
                      <ServiceRequestStatusLogForm
                        setServiceRequestLogModal={setServiceRequestLogModal}
                        logServiceRequestId={logServiceRequestId}
                      />
                    </Card>
                  </Modal>

                  <Modal
                    open={calibrateDeviceModal}
                    onClose={handleCalibrateDeviceModalClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                  >
                    <Card
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "90%", sm: "70%", md: "50%" }, // Set the desired width here
                        backgroundColor: "white",
                        paddingTop: 1,
                      }}
                    >
                      <CalibrateDeviceForm
                        deviceName={name?.text}
                        currentLevel={percent}
                        // externalId = {device?.external_device_id}
                        externalId={paramValue?.id}
                        showToast={showToast}
                        handleModalClose={handleCalibrateDeviceModalClose}
                      />
                    </Card>
                  </Modal>

                  <Modal
                    open={deviceDisconnectModal}
                    onClose={handleDeviceDisconnectModalClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                  >
                    <Card
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "90%", sm: "70%", md: "50%" }, // Set the desired width here
                        backgroundColor: "white",
                        paddingTop: 1,
                      }}
                    >
                      <DeviceDisconnectForm
                        deviceName={name?.text}
                        localIP={paramValue?.local_ip}
                        // externalId = {device?.external_device_id}
                        externalId={paramValue?.id}
                        showToast={showToast}
                        handleModalClose={handleDeviceDisconnectModalClose}
                      />
                    </Card>
                  </Modal>

                  <Modal
                    open={openServiceModal}
                    onClose={handleServiceModalClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                  >
                    <Card
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "90%", sm: "70%", md: "50%", lg: "40%" }, // Set the desired width here
                        height: {
                          xs: "90vh",
                          sm: "90vh",
                          md: "700px",
                          lg: "700px",
                        },
                        backgroundColor: "white",
                        paddingTop: "15px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <ServiceRequestsForm
                        deviceId={id}
                        device={device?.attributes}
                        serviceRequestEditFlag={serviceRequestEditFlag}
                        serviceRequest={serviceRequest}
                        setServiceModal={setServiceModal}
                        setServiceRequests={setServiceRequests}
                        setServiceStatusUpdate={setServiceStatusUpdate}
                        showToast={showToast}
                      />
                    </Card>
                  </Modal>
                  <Modal open={openModal} onClose={() => setOpenModal(false)}>
                    <MDBox tabIndex={-1}>
                      <GoogleMapComponent lat={device?.attributes?.EntityAddressItem?.Address?.latitude} lng={device?.attributes?.EntityAddressItem?.Address?.longitude} />
                    </MDBox>
                  </Modal>
                  <Modal
                    open={phoneEnvModal}
                    onClose={() => setPhoneEnvModal(false)}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                  >
                    <Card
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",

                        width: isTabletOrMobile ? "80%" : "20%", // Set the desired width here
                        backgroundColor: "white",
                        p: 4,
                      }}
                    >
                      <h4 className="text-center" >Select Phone environment</h4>
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <th scope="row" className="text-sm-end text-start">
                              <MDTypography fontSize={1}>
                                <span
                                  style={{ fontWeight: "700" }}
                                >
                                  Production Phone:
                                </span>

                              </MDTypography>
                            </th>
                            <td style={styles.cell} >
                              <Switch checked={phoneEnv.is_production} onChange={(e) => handlePhoneSwitch(e.target.checked, 'is_production')}/>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" className="text-sm-end text-start">
                              <MDTypography fontSize={1}>
                                <span
                                  style={{ fontWeight: "700" }}
                                >
                                  Sandbox Phone:
                                </span>

                              </MDTypography>
                            </th>
                            <td style={styles.cell} >
                              <Switch checked={phoneEnv.is_sandbox} onChange={(e) => handlePhoneSwitch(e.target.checked, 'is_sandbox')}/>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" className="text-sm-end text-start">
                              <MDTypography fontSize={1}>
                                <span
                                  style={{ fontWeight: "700" }}
                                >
                                  Development Phone:
                                </span>

                              </MDTypography>
                            </th>
                            <td style={styles.cell} >
                              <Switch checked={phoneEnv.is_development} onChange={(e) => handlePhoneSwitch(e.target.checked, 'is_development')}/>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <MDButton
                          variant="gradient"
                          color="secondary"
                          size="small"
                          px={2}
                          my={2}
                          sx={{
                            marginLeft: "10px",
                          }}
                          onClick={() => setPhoneEnvModal(false)}
                        >
                          Cancel
                        </MDButton>
                        <MDButton
                          variant="gradient"
                          color="secondary"
                          size="small"
                          px={2}
                          my={2}
                          sx={{
                            marginLeft: "10px",
                          }}
                          onClick={clickSetPhoneOption}
                        >
                          Save
                        </MDButton>
                      </div>
                    </Card>
                  </Modal>
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="Tabs"
                    sx={{ display: "flex" }}
                  >
                    <Tab
                      sx={{ flex: "1", p: "1" }}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            p: "2",
                            alignItems: "center",
                            fontSize: { xs: "0.85rem", sm: "1rem" },
                            gap: { xs: "2px", sm: "8px" },
                          }}
                        >
                          <i
                            className="fas fa-file-alt"
                            style={{ marginRight: "2px" }}
                          ></i>
                          Logs
                        </Box>
                      }
                    />
                    <Tab
                      sx={{ flex: "1", p: "1" }}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            p: "2",
                            alignItems: "center",
                            fontSize: { xs: "0.85rem", sm: "1rem" },
                            gap: { xs: "2px", sm: "8px" },
                          }}
                        >
                          <i
                            className="fas fa-exclamation-triangle"
                            style={{ marginRight: "1px" }}
                          ></i>
                          Alerts
                        </Box>
                      }
                    />
                    <Tab
                      sx={{ flex: "1", p: "1" }}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            p: "2",
                            alignItems: "center",
                            fontSize: { xs: "0.85rem", sm: "1rem" },
                            gap: { xs: "2px", sm: "8px" },
                          }}
                        >
                          <i
                            className="fas fa-code-branch"
                            style={{ marginRight: "1px" }}
                          ></i>
                          Rules
                        </Box>
                      }
                    />
                    <Tab
                      sx={{ flex: "1.5", p: "2" }}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: { xs: "0.85rem", sm: "1rem" },
                            gap: { xs: "0px", sm: "8px" },
                          }}
                        >
                          <i
                            className="fas fa-tools"
                            style={{ marginRight: "0px" }}
                          ></i>
                          <Typography
                            sx={{ fontSize: { xs: "0.825rem", sm: "1rem" } }}
                          >
                            Service Request
                          </Typography>
                        </Box>
                      }
                    />
                  </Tabs>

                  {selectedTab === 0 && (
                    <DataTable table={dataTableData} canSearch={true} loader={loaderT} />
                  )}
                  {selectedTab === 1 && (
                    <>
                      <Modal
                        open={openViewModal}
                        onClose={handleViewClose}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                      >
                        <Card
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "80%", // Set the desired width here
                            backgroundColor: "white",
                            boxShadow: 24,
                            p: 4,
                            maxHeight: "70vh", // Set max height to enable scrolling
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          <CardContent>
                            <DynamicDataTable Alert={alert} />
                          </CardContent>
                        </Card>
                      </Modal>

                      <MDBox pt={6} pb={3}>
                        <MDBox mb={3}>
                          <CollapsibleTable
                            width="100%"
                            data={alertTableData}
                            columns={alertColumns}
                            clickViewHandler={clickViewHandler}
                          />
                        </MDBox>
                      </MDBox>
                    </>
                  )}
                  {selectedTab === 2 && (
                    <>
                      <MDBox
                        p={3}
                        lineHeight={1}
                        display="flex"
                        justifyContent="right"
                      >
                        <MDButton
                          color="dark"
                          size="small"
                          onClick={() => {
                            setHandleMode(false);
                            setAlertRule();
                            setOpenViewModal(true);
                          }}
                        >
                          Add Rule
                        </MDButton>
                      </MDBox>
                      <DataTable
                        table={alertRuledataTableData}
                        canSearch={true}
                      />
                    </>
                  )}
                  {selectedTab === 3 && (
                    <MDBox sx={{ marginTop: "20px" }}>
                      {/**<MDBox>
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="small"
                          px={2}
                          my={2}
                          mx={2}
                          onClick={clickProductionPhone}
                        >
                          Production Phone
                        </MDButton>

                        <MDButton
                          variant="gradient"
                          color="secondary"
                          size="small"
                          px={2}
                          my={2}
                          sx={{
                            marginLeft: "10px",
                          }}
                          onClick={clickTestPhone}
                        >
                          Test Phone
                        </MDButton>
                      </MDBox> */}
                      <MDBox>
                        <MDButton
                          variant={"gradient"}
                          color="primary"
                          size="small"
                          px={2}
                          my={2}
                          mx={2}
                          onClick={() => setPhoneEnvModal(true)}
                        >
                          Open Phone
                        </MDButton>

                      </MDBox>
                      {checkRowSelectedRowsFlag && (
                        <MDBox>
                          <MDButton
                            variant="gradient"
                            color="primary"
                            size="small"
                            px={2}
                            my={2}
                            mx={2}
                            onClick={clickStatusEdit}
                          >
                            Edit
                          </MDButton>

                          <MDButton
                            variant="gradient"
                            color="secondary"
                            size="small"
                            px={2}
                            my={2}
                            sx={{
                              marginLeft: "10px",
                            }}
                            onClick={clickCancelSelection}
                          >
                            Cancel Selection
                          </MDButton>
                        </MDBox>
                      )}

                      <ServiceRequestDataTable
                        table={serviceRequestdataTableData}
                        canSearch={true}
                        onRowClick={clickServiceReqeustRowHandler}
                        handleSelectedRows={handleSelectedRows}
                        selectedRows={selectedRows}
                        setDataTableUpdate={setDataTableUpdate}
                      />
                    </MDBox>
                  )}

                  <MDBox
                    ml="auto"
                    mt={4}
                    mb={2}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() =>
                          navigate("/internal-device-management", {
                            state: {
                              value: false,
                              text: "",
                            },
                          })
                        }
                      >
                        Back
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default ViewDevice;
