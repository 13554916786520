import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { styled } from "@mui/system";
import moment from "moment";
import {
    Container,
    Typography,
    TextField,
    Modal,
    Card,
    CardContent,
    CardHeader,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CrudService from "services/cruds-service";

import DataTable from "examples/Tables/DataTable";
import crudsService from "services/cruds-service";

const DeviceDisconnectForm = React.memo(
    ({deviceName, localIP, externalId, showToast, handleModalClose}) => {
       
        const [formData, setFormData] = useState({
            local_ip: localIP,
        });

        const handleSubmit = async() => {
           
                try{
                    
                    const url = `http://${localIP}/disconnect`;
                    window.open(url,'_blank');
                    handleModalClose();
                   
                }catch(error){
                    console.log(error);
                }
            

        }

        const formValidation = (formData) => {
            if(!formData.device_name){
                alert("Device Name is undeined. So You can't submit the form.");
                return false;
            }
           
            if (!formData.calibration_value){
                alert("Calibration Value is undeined. So You can't submit the form.");
                return false;
            }

            return true;

        }

        return (
            <Container sx={{paddingRight: '0px !important', paddingLeft:'0px !important'}}>
                <CardHeader
                    variant="h4"
                    component="h2"
                    title={`Disconnect Device: ${deviceName}`}
                    sx={{ display: "flex", alignItems: "center", borderBottom: "1px solid #dee2e6" }}
                />
               
                <CardContent
                    sx={{
                        maxHeight: "70vh", // Set max height to enable scrolling
                        overflowY: "auto",
                        overflowX: "hidden",
                        borderBottom: "1px solid #dee2e6"

                    }}
                >
                   <MDTypography  
                       style={{
                                fontSize: "14px",
                               
                            }}>
                            Are you sure you wish to disconnect this device?
                        </MDTypography>

                </CardContent>

                <MDBox sx={{display:'flex', gap:'10px', marginTop:'20px', marginRight:'20px', marginBottom:'20px', float:"right"}}>
                   <MDButton
                        color="dark"
                        onClick={handleModalClose}
                    >
                       Cancel
                    </MDButton>
                    <MDButton
                        color="error"
                        onClick={handleSubmit}
                    >
                       Confirm
                    </MDButton>
                </MDBox>
           
            </Container>
        );
    }
);

export default DeviceDisconnectForm;
