import React from "react";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import MDBox from "components/MDBox";
import { Line } from "react-chartjs-2";

const ParamValuesChart = ({ paramValues }) => {
  // Extract and sort the data based on the 'created_at' timestamp
  const sortedValues = paramValues.sort(
    (a, b) => new Date(a.created_at) - new Date(b.created_at)
  );
  const uniqueDates = [
    ...new Set(
      sortedValues.map(
        (item) => new Date(item.created_at).toISOString().split("T")[0]
      )
    ),
  ];

  // Get the last 7 unique dates
  const last7UniqueDates = uniqueDates.slice(-7);

  // Filter the sortedValues to include only the values for the last 7 unique dates
  const filteredValues = sortedValues.filter((item) => {
    const itemDate = new Date(item.created_at).toISOString().split("T")[0];
    return last7UniqueDates.includes(itemDate);
  });

  const chartData = {
    labels: last7UniqueDates,
    datasets: {
      label: "Percent Level",
      data: filteredValues.map((item) => parseInt(item?.value?.percent_level)),
    },
  };

  const chartDataNew = {
    labels: last7UniqueDates,

    datasets: [
      {
        label: "Fill Level (%)",
        fill:true,
        data: filteredValues.map((item) =>
          parseInt(item?.value?.percent_level)
        ),

        borderColor: "rgb(82, 86, 109)", // Grayish Blue for the line color

        backgroundColor: "rgba(82, 86, 109, 0.2)", // Semi-transparent Grayish Blue fill

        borderWidth: 3, // Thicker line for better visibility

        pointBackgroundColor: "rgb(82, 86, 109)", // Grayish Blue data points

        pointBorderColor: "rgb(255, 255, 255)", // White border for data points

        pointRadius: 5, // Make data points larger for better visibility

    

        tension: 0.4, // Smooth curve for the line
      },
    ],
  };

  const chartOptionsNew = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,

        grid: {
          color: "rgba(230, 230, 230, 0.2)", // Light Gray for gridlines with slight transparency
        },

        ticks: {
          color: "rgb(230, 230, 230)", // Light Gray Y-axis labels
        },
      },

      x: {
        grid: {
          color: "rgba(230, 230, 230, 0.2)", // Light Gray for gridlines with slight transparency
        },

        ticks: {
          color: "rgb(230, 230, 230)", // Light Gray X-axis labels
        },
      },
    },

    plugins: {
      legend: {
        labels: {
          color: "rgb(230, 230, 230)", // Light Gray for legend text
        },
      },
    },
  };

  return (
    <MDBox mt={5} mb={5}>
      <div
        style={{
          backgroundColor: "rgb(38, 40, 51)",
          padding: "10px",
          borderRadius: "10px",
          height:'300px',
          width:'100%'
        }}
      >
        <Line data={chartDataNew} options={chartOptionsNew} />
      </div>
    </MDBox>
  );
};

export default ParamValuesChart;
